import useKeywordSearch from "./useKeywordSearch"
import {
  linkForDeltaFromPosition,
} from "../../../utils/positionUtils"

const useKeywordList = () => {
  const { keywords, keywordsLoading } = useKeywordSearch()
  const keywordsWithLinks = Array.isArray(keywords)
    ? keywords.map((keyword, idx) => {
        return {
          ...keyword,
          id: `${
            keyword.caption ? keyword.caption.replaceAll(" ", "_") : "caption"
          }_${idx}`,
          link: linkForDeltaFromPosition(null, keyword.coord, true),
        }
      })
    : []
  return { keywords: keywordsWithLinks, keywordsLoading }
}

export default useKeywordList
