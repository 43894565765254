import React, { forwardRef } from "react"
import classNames from "classnames"
import {
  HomeButton,
  PhotoUploadButton,
  TextSearchField,
  Icon,
  icons,
  iconsColors,
} from "./index"
import useNavigator from "../hooks/useNavigator"
import usePanel from "../hooks/usePanel"
import useDialog from "../hooks/useDialog"

import * as styles from "./header.module.css"

export const Header = forwardRef(
  ({ homeDir, isDropdownOpen, searchText, handleSearchTextChange }, ref) => {
    const { location } = useNavigator()
    const { isPanelOpen } = usePanel()
    const { isDialogOpen } = useDialog()

    return (
      <div
        className={classNames(styles.appHeader, {
          [styles.panelOpen]: isPanelOpen,
          [styles.dialogOpen]: isDialogOpen,
        })}
      >
        <div className={classNames({ [styles.dropdownOpen]: isDropdownOpen })}>
          <HomeButton pathname={homeDir} />
        </div>
        <div
          className={classNames(styles.headerSearch, {
            [styles.active]: isDropdownOpen,
          })}
        >
          <TextSearchField
            active={isDropdownOpen}
            searchText={searchText}
            handleSearchTextChange={searchText => {
              handleSearchTextChange(searchText)
            }}
            ref={ref}
          />

          <div
            className={classNames({ [styles.dropdownOpen]: isDropdownOpen })}
          >
            <PhotoUploadButton location={location} />
          </div>
        </div>
      </div>
    )
  }
)
