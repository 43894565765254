import { useState, useRef, useEffect } from "react"
import { useSearchQuery } from "../keywordSearchSlice"
import useVersioning from "../../../hooks/useVersioning"
import { useDispatch } from "react-redux"
import { setSearchText } from "../../../store/globalState"
import { useSelector } from "react-redux"

const useTextSearchField = () => {
  const [searchTextLocal, setSearchTextLocal] = useState("")
  const textRef = useRef()
  const { deploymentVersion } = useVersioning()
  const dispatch = useDispatch()
  const searchText = useSelector(store => store.globalState.searchText)
  useSearchQuery({
    text: searchTextLocal,
    deployment_id: deploymentVersion,
  })

  const handleTextChange = event => {
    textRef.current = event && event.target ? event.target.value : ""
    setSearchTextLocal(state =>
      textRef.current !== state ? textRef.current : state
    )
    setTimeout(() => {
      dispatch(setSearchText(textRef.current))
    }, 100)
  }

  const textInputParams = {
    onChange: handleTextChange,
    value: searchTextLocal,
  }

  const handleClearClick = () => {
    dispatch(setSearchText(""))
  }

  useEffect(() => {
    setSearchTextLocal(state => {
      return searchText !== state && searchText === "" ? searchText : state
    })
  }, [searchText])

  return {
    active: searchTextLocal !== "",
    textInputParams,
    handleClearClick,
  }
}

export default useTextSearchField
