import React from "react"

import { FilterIndicator, FilterLabel, Checkbox } from "../../components/index"

import * as styles from "./detectedProp.module.css"

export const DetectedProp = ({ prop, selected, handleClick }) => {
  return (
    <div className={styles.detectedProp} onClick={handleClick}>
      <div className={styles.indicatorContainer}>
        <FilterIndicator
          panelName={prop.prop}
          caption={prop.caption}
          imageUrl={prop.image}
        />
        <FilterLabel name={prop.prop} caption={prop.caption} />
      </div>
      <Checkbox checked={selected} />
    </div>
  )
}
