import { createSlice } from "@reduxjs/toolkit"

const globalSlice = createSlice({
  name: "globalState",
  initialState: {
    errors: [],
    openDialog: null,
    searchText: "",
    filterPanels: {},
    scrollOffset: -1,
  },
  reducers: {
    setError: (state, action) => {
      state.errors.push(action.payload)
    },
    resetLastError: state => {
      state.errors = state.errors.slice(0, -1)
    },
    resetErrors: state => {
      state.errors = []
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setPanelData: (state, action) => {
      const { panel, data } = action.payload
      state.filterPanels[panel] = data
    },
    setScroll: (state, action) => {
      state.scrollOffset = action.payload
    },
  },
})

export const {
  setSearchText,
  setPanelData,
  setOpenDialog,
  setError,
  resetLastError,
  resetErrors,
  setScroll,
} = globalSlice.actions

export default globalSlice.reducer
export const selectOffset = store => store.globalState.scrollOffset
