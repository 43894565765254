import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useResponsiveView } from "../../hooks"
import {
  Icon,
  icons,
  iconsColors,
  TextSearchButton,
} from "../../components/index"
import { labels } from "../../utils/labelConstants"
import useLocale from "../../hooks/useLocale"

import * as styles from "./textSearch.module.css"

const suggestionsArray = {
  "women's shoes": {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-footwear_60.png",
    link: "/footwear/women/",
  },
  pumps: {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_highheel_60.png",
    link: "/footwear/women/pumps/",
  },
  sneakers: {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-shoe_sneaker_60.png",
    link: "/footwear/women/sneakers/",
  },
  ballerinas: {
    icon: "https://s3.eu-central-1.amazonaws.com/temp-assets.gasefi.com/s-button-w-pump_ballet_60.png",
    link: "/footwear/women/?shape=WyJmYS5jYXQuc2hvZS53LmJhbGxldF9zaG9lIl0=",
  },
}

export const TextSearch = ({ inLanding }) => {
  const { isLargeMobile } = useResponsiveView()

  const [searchField, setSearchField] = useState("")
  const [showSuggestions, setShowSuggestions] = useState(false)

  const locale = useLocale()

  const suggestionsRef = useRef(null)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideSuggestions)

    const storage = window.localStorage.getItem("gasefiInput")
    if (typeof storage === "string" && storage.length > 0)
      setSearchField(storage)

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSuggestions)
      window.localStorage.clear("gasefiInput")
    }
  }, [])

  useEffect(() => {
    window.localStorage.clear("gasefiInput")

    if (searchField.length > 0) setShowSuggestions(true)
  }, [searchField])

  const handleClickOutsideSuggestions = event => {
    if (!suggestionsRef.current.contains(event.target)) {
      setSearchField("")
      setShowSuggestions(false)
    }
  }

  const suggestions = Object.keys(suggestionsArray).filter(item =>
    item.includes(searchField)
  )

  const renderSuggestion = item => {
    const suggestionSplit = item.split(searchField)
    const length = suggestionSplit.length

    return suggestionSplit.map((phrase, index) => (
      <p key={phrase + index}>
        <span className={styles.searchTextHighlighted}>{phrase}</span>
        {index !== length - 1 ? <span>{searchField}</span> : null}
      </p>
    ))
  }

  const closeDropdown = () => {
    setSearchField("")
    setShowSuggestions(false)
  }

  const textSearchOverlayActive = showSuggestions && isLargeMobile

  return (
    <div
      ref={suggestionsRef}
      className={classNames(styles.textSearchWrapper, {
        [styles.textSearchOverlay]: textSearchOverlayActive,
      })}
    >
      <div
        className={classNames(styles.searchIcon, {
          [styles.searchIconInOverlay]: textSearchOverlayActive,
        })}
      >
        <Icon icon={icons.search} color={iconsColors.GREY_LIGHT} />
      </div>
      <div
        className={classNames({
          [styles.searchInputWrapperInOverlay]: textSearchOverlayActive,
        })}
      >
        <label
          htmlFor="password"
          style={{ position: "absolute", opacity: "0" }}
        >
          Password
        </label>
        <input
          type="text"
          id="password"
          name="password"
          autoComplete="off"
          placeholder={labels[locale].SEARCH_QUERY_PLACEHOLDER}
          value={searchField}
          onChange={event => setSearchField(event.target.value)}
          onClick={() => setShowSuggestions(true)}
          className={classNames(styles.searchInput, {
            [styles.searchInputInOverlay]: textSearchOverlayActive,
            [styles.landing]: inLanding,
          })}
        />
      </div>
      {textSearchOverlayActive ? (
        <div className={styles.closeIcon} onClick={closeDropdown}>
          <Icon icon={icons.close} color={iconsColors.GREY} />
        </div>
      ) : null}

      {(searchField.length > 0 && showSuggestions) ||
      textSearchOverlayActive ? (
        <div
          className={classNames(styles.dropdown, {
            [styles.inLanding]: inLanding,
            [styles.dropdownInOverlay]: textSearchOverlayActive,
          })}
        >
          {suggestions.length > 0 ? (
            suggestions.map(item => (
              <div key={item} className={styles.suggestionLink}>
                <TextSearchButton pathname={suggestionsArray[item].link}>
                  <img
                    className={styles.suggestionImage}
                    src={suggestionsArray[item].icon}
                    alt={item}
                  />
                  {renderSuggestion(item)}
                </TextSearchButton>
              </div>
            ))
          ) : (
            <div className={styles.noSuggestions}>
              {labels[locale].SEARCH_QUERY_NO_SUGGESTIONS}
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

TextSearch.propTypes = {
  inLanding: PropTypes.bool,
}

TextSearch.defaultProps = {
  inLanding: false,
}
