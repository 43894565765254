import React, { useState } from "react"
import classNames from "classnames"
import * as styles from "./filtribute.module.css"
import * as buttonStyles from "../../../styles/buttonStyles.module.css"

import { FilterIcon, panelKeys, emptyImage, Icon, icons } from "../../index"

export const AddPanelFiltribute = ({
  value,
  inCollection,
  isCollection,
  isSelected,
  caption,
  details,
  imageUrl,
  isMobile,
  isOpen,
}) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className={classNames(
        styles.addPanelFiltribute,
        { [styles.inCollection]: inCollection },
        { [styles.collection]: isCollection === true },
        { [styles.selected]: isSelected },
        { [styles.hovered]: hover }
      )}
      style={{ position: "relative" }}
      onMouseOver={() => {
        setHover(true)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
    >
      {isSelected ? (
        <>
          {!isMobile ? (
            <FilterIcon panelId={value} />
          ) : value === panelKeys.SHAPE_FILTER ? (
            <img
              src={imageUrl !== "#" ? imageUrl : emptyImage}
              width={"50px"}
              height={"50px"}
            />
          ) : (
            <img
              src={imageUrl !== "#" ? imageUrl : emptyImage}
              width={"36px"}
              height={"36px"}
              style={{ borderRadius: "100px", margin: "0 7px" }}
            />
          )}
          <div style={{ display: "flex", overflow: "hidden", width: "100%" }}>
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                flexShrink: "0",
              }}
            >
              {caption}
            </div>
            <div className={styles.addFilterDetails}>
              <p>{details}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <FilterIcon panelId={value} />
          <div style={{ textTransform: "capitalize", marginLeft: "10px" }}>
            {caption}
          </div>
        </>
      )}
      {isCollection ? (
        <div
          className={classNames(buttonStyles.roundFloatingControlledHover, {
            [buttonStyles.hover]: hover,
          })}
        >
          {isOpen ? (
            <Icon icon={icons.arrowUp} />
          ) : (
            <Icon icon={icons.arrowDown} />
          )}
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
    </div>
  )
}
