import useNavigator from "../../../hooks/useNavigator"
import usePanel from "../../../hooks/usePanel"
import useDialog from "../../../hooks/useDialog"
import useProducts from "../../../hooks/useProducts"

const useResults = () => {
  const { location } = useNavigator()
  const { isPanelOpen: isOpenFilterPanel } = usePanel()
  const { isDialogOpen: isOpenDialog } = useDialog()

  const { heapView, loadingHeapView: loading } = useProducts({ location })

  return {
    isOpenFilterPanel,
    isOpenDialog,
    heapView,
    loading,
    productCount: heapView?.count,
  }
}

export default useResults
