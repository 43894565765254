import useVisualSelector from "../../products/productCard/hooks/useVisualSelector"

const useProductCardExpanded = ({ product }) => {
  const {
    handleSelectVisual: handleClick,
    selectedVisualIdx: selectedOptionIndex,
    colorOptions,
  } = useVisualSelector(product)

  let selectedOption = {}
  if (Array.isArray(product?.colorOptions)) {
    if (selectedOptionIndex != null && selectedOptionIndex > -1) {
      selectedOption = product?.colorOptions.find(
        colorOption => colorOption.index === selectedOptionIndex
      )
    } else {
      selectedOption = product?.colorOptions[0]
    }
  }

  return {
    selectedOptionIndex,
    handleClick,
    selectedOption,
    colorOptions,
  }
}

export default useProductCardExpanded
