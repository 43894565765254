import React, { useEffect, useRef } from "react"
import { ariaRoles } from "../../utils/constants"
import { ChooseValueButton, SectionHeader, Grid } from "../index.js"
import { CategoryFiltribute } from "./filtributes/categoryFiltribute"
import { labels } from "../../utils/labelConstants"
import { Icon, icons } from "../index"
import useLocale from "../../hooks/useLocale.js"
import useNavigator from "../../hooks/useNavigator.js"
import useFilters from "../../features/filters/hooks/useFilters.js"
import { panelKeys } from "../../utils/constants"
import * as selectorStyles from "./selector.module.css"
import * as buttonStyles from "../../styles/buttonStyles.module.css"

export const CategorySelector = ({ pageArray, onPageChange }) => {
  const locale = useLocale()
  const { position } = useNavigator()
  const { filterPanel: filterPanelObj } = useFilters({
    caller: panelKeys.SHAPE_FILTER,
  })

  const cols = 3
  const rows = 2
  const [filtributesInPage, pageCount] = filterPanelObj.getFiltributePage(
    cols,
    rows,
    pageArray[0]
  )
  const parentNameRef = useRef(filterPanelObj.parent.name)
  const pageArrayRef = useRef()

  if (typeof pageArrayRef.current === "undefined") {
    var pageWithSelection = 0
    for (let i = 0; i < pageCount; i++) {
      const [filtributesInPage_] = filterPanelObj.getFiltributePage(
        cols,
        rows,
        i
      )
      const selectedIndex = filtributesInPage_.findIndex(
        filtribute => filtribute.selected
      )
      if (selectedIndex > -1) {
        pageWithSelection = i
      }
    }
    if (pageWithSelection !== pageArray[0]) {
      onPageChange([pageWithSelection])
    }
  }
  pageArrayRef.current = pageArray

  const handleBackClick = () => {
    onPageChange([Math.max(pageArray[0] - 1, 0)])
  }

  const handleForwardClick = () => {
    onPageChange([Math.min(pageArray[0] + 1, pageCount - 1)])
  }

  useEffect(() => {
    if (filterPanelObj.parent.name !== parentNameRef.current) {
      parentNameRef.current = filterPanelObj.parent.name
      onPageChange([0])
    }
    // eslint-disable-next-line
  }, [filterPanelObj.parent.name, onPageChange])

  const selectionList = filtributesInPage.map(item => {
    return (
      <ChooseValueButton
        key={item.id}
        pathname={item.getLinkUrl(position)}
        enabled={item.count > 0}
      >
        <CategoryFiltribute
          url={item.imageUrl}
          alt={item.imageAlt}
          isSelected={item.selected}
          caption={item.truncatedCaption}
          count={item.count}
          hasChildren={item.hasChildren}
          multiselect={item.isMultiselect}
        />
      </ChooseValueButton>
    )
  })

  return (
    <>
      <SectionHeader>{labels[locale].CATEGORY_SUGGESTIONS}</SectionHeader>
      <div className={selectorStyles.sectionBodyCentered} role={ariaRoles.LIST}>
        <Grid itemWidth={86}>{selectionList}</Grid>
        {pageCount > 0 ? (
          <>
            {pageArray[0] > 0 && (
              <div
                className={buttonStyles.carouselButtonBack}
                onClick={handleBackClick}
              >
                <Icon icon={icons.arrowBack} />
              </div>
            )}
            {pageArray[0] < pageCount - 1 && (
              <div
                className={buttonStyles.carouselButtonForward}
                onClick={handleForwardClick}
              >
                <Icon icon={icons.arrowForward} />
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  )
}
