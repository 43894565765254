import React from "react"
import useErrors from "../../hooks/useErrors"
import * as styles from "./errorHandler.module.css"

const ErrorHandler = () => {
  const { errors } = useErrors()

  if (errors.length < 1) return null
  return (
    <div className={styles.errorContainer}>
      {errors.map(error => (
        <p key={error.id} className={styles.errorLine}>
          {error.message}
        </p>
      ))}
    </div>
  )
}

export default ErrorHandler
