import React, { forwardRef } from "react"
import classNames from "classnames"
import { Icon, icons, iconsColors } from "../../components/index"
import { labels } from "../../utils/labelConstants"
import useLocale from "../../hooks/useLocale"
import useTextSearchField from "./hooks/useTextSearchField"

import * as styles from "./textSearchField.module.css"

export const TextSearchField = forwardRef(({ landing = false }, ref) => {
  const { textInputParams, handleClearClick, active } = useTextSearchField()
  var locale = useLocale()
  return (
    <div
      ref={ref}
      className={classNames(styles.textSearchContainer, {
        [styles.active]: active,
        [styles.landing]: landing,
      })}
    >
      <input
        type="text"
        placeholder={labels[locale].SEARCH_QUERY_PLACEHOLDER}
        className={classNames(styles.searchInput, {
          [styles.active]: active,
          [styles.landing]: landing,
        })}
        {...textInputParams}
      />
      <div
        className={classNames(styles.searchIcon, { [styles.active]: active })}
        onClick={handleClearClick}
      >
        {active ? (
          <Icon icon={icons.close} color={iconsColors.BLACK} />
        ) : (
          <Icon icon={icons.search} color={iconsColors.GREY_LIGHT} />
        )}
      </div>
    </div>
  )
})
