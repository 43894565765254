import { createSlice } from "@reduxjs/toolkit"

const productsSlice = createSlice({
  name: "products",
  initialState: {
    sortingIndex: 0,
    productPage: 0,
    selectedVisuals: {},
  },
  reducers: {
    setSortingIndex: (state, action) => {
      state.sortingIndex = action.payload
    },
    setProductPage: (state, action) => {
      state.productPage = action.payload
    },
    setSelectedVisual: (state, action) => {
      const { productId, visualIdx } = action.payload
      state.selectedVisuals[productId] = visualIdx
    },
    resetSelectedVisuals: state => {
      state.selectedVisuals = {}
    },
  },
})

export const { setSortingIndex, setProductPage, setSelectedVisual, resetSelectedVisuals } =
  productsSlice.actions
export default productsSlice.reducer
export const selectSortingIndex = store => store.products.sortingIndex
export const selectProductPage = store => store.products.productPage
export const selectSelectedVisuals = store => store.products.selectedVisuals
