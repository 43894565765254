import React, { useState } from "react"
import { ChooseValueButton, List, ListItem, TextInput } from "../index.js"
import useNavigator from "../../hooks/useNavigator.js"
import useFilters from "../../features/filters/hooks/useFilters.js"
import { panelKeys } from "../index.js"
import * as styles from "./selector.module.css"

export const VendorSelector = () => {
  const [textFilterValue, setTextFilterValue] = useState("")
  const { position } = useNavigator()
  const { filterPanel: filterPanelObj } = useFilters({
    caller: panelKeys.VENDOR_FILTER,
  })

  const filteredFiltributes = filterPanelObj.filtributes.filter(filtribute => {
    return filtribute.text.toLowerCase().includes(textFilterValue.toLowerCase())
  })

  var activeSelectionList = filteredFiltributes.map((selection, index) => {
    if (selection.selected) {
      return (
        <div key={index}>
          <ChooseValueButton
            key={index}
            pathname={selection.getLinkUrl(position)}
            enabled={selection.count > 0}
          >
            <ListItem
              isSelected={true}
              caption={selection.text}
              count={selection.count}
            ></ListItem>
          </ChooseValueButton>
        </div>
      )
    } else return null
  })
  activeSelectionList = activeSelectionList.filter(item => item !== null)

  var selectionList = filteredFiltributes.map((selection, index) => {
    if (!selection.selected && selection.count > 0) {
      return (
        <div key={index}>
          <ChooseValueButton
            key={index}
            pathname={selection.getLinkUrl(position)}
            enabled={selection.count > 0}
          >
            <ListItem
              isSelected={false}
              caption={selection.text}
              count={selection.count}
            ></ListItem>
          </ChooseValueButton>
        </div>
      )
    } else return null
  })
  selectionList = selectionList.filter(item => item !== null)

  return (
    <>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <TextInput value={textFilterValue} onChange={setTextFilterValue} />
      </div>
      {activeSelectionList.length > 0 ? (
        <List>{activeSelectionList}</List>
      ) : (
        <div style={{ display: "none" }} />
      )}
      <div className={styles.scrollingList}>
        <List>
          {selectionList.length > 0 ? (
            selectionList
          ) : (
            <div style={{ display: "none" }} />
          )}
        </List>
      </div>
    </>
  )
}
