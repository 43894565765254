import React, { useState, useEffect, useRef } from "react"
import useLocale from "../../hooks/useLocale"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ariaRoles } from "../../utils/constants"
import {
  labels,
  DropArea,
  UploadsObj,
  UploadItem,
  UploadPreview,
  NewSearchButton,
  UploadCancelButton,
  StartProcessingButton,
  Loader,
  useMobile,
  Image,
} from "../../components/index"

import * as styles from "./photoUploadPanel.module.css"
import { cMobile } from "./photoUploadPanel.module.css"

export const PhotoUploadPanel = ({
  location,
  position,
  setTitle,
  setNewPosition,
  mobilePreview,
  setMobilePreview,
  uploadedImage,
  setUploadedImage,
  processing,
  handleStartProcessingClick,
  uploads = new UploadsObj(),
}) => {
  const isMobile = useMobile()
  const processingRef = useRef()
  const uploadsRef = useRef()
  const locale = useLocale()
  const [selectedItem, _setSelectedItem] = useState(0)
  const [selectedProps, setSelectedProps] = useState()
  const [newSearch, setNewSearch] = useState()

  const getNewPosition = (selectedItem, selectedProps) => {
    var result = JSON.parse(JSON.stringify(position))
    if (
      uploads.items.length > 0 &&
      selectedItem > -1 &&
      selectedItem < uploads.items.length
    ) {
      uploads.items[selectedItem].detectedProps.map((detectedProp, index) => {
        if (selectedProps && selectedProps[index])
          result[detectedProp.prop] = detectedProp.coord
      })
    }
    return result
  }

  const setSelectedItem = index => {
    _setSelectedItem(index)
    if (isMobile) setMobilePreview(true)
  }

  const handlePropSelection = index => {
    if (selectedProps === undefined) return false
    var propSelection = JSON.parse(JSON.stringify(selectedProps))
    propSelection[index] = !propSelection[index]
    setSelectedProps(propSelection)
    setNewPosition(getNewPosition(selectedItem, propSelection))
  }

  const handleNewSearchClick = () => {
    setNewSearch(true)
  }

  const handleCalcelSearchClick = () => {
    setUploadedImage(null)
    setNewSearch(false)
  }

  useEffect(() => {
    if (processing && !processingRef.current) {
      uploadsRef.current = uploads
      processingRef.current = true
    }
  }, [processing])

  useEffect(() => {
    if (typeof uploads.items[selectedItem] !== "undefined") {
      const propSelection = uploads.items[selectedItem].detectedProps.map(
        prop => true
      )
      setSelectedProps(propSelection)
      setNewPosition(getNewPosition(selectedItem, propSelection))
    }
  }, [selectedItem])

  useEffect(() => {
    if (isMobile && mobilePreview) {
      setNewPosition(getNewPosition(selectedItem, selectedProps))
    }
  }, [isMobile, mobilePreview])

  useEffect(() => {
    if (
      uploads.items &&
      uploadsRef.current &&
      uploadsRef.current.items &&
      uploads.items.length !== uploadsRef.current.items.length
    ) {
      uploadsRef.current = uploads
      if (processingRef.current === true) {
        processingRef.current = false
        setNewSearch(false)
        if (selectedItem !== 0) {
          setSelectedItem(0)
          setMobilePreview(false)
        } else {
          const propSelection =
            uploads && uploads.items && uploads.items[0]
              ? uploads.items[0].detectedProps.map(prop => true)
              : []
          setSelectedProps(propSelection)
          setMobilePreview(true)
        }
      }
    }
  }, [uploads])

  useEffect(() => {
    if (newSearch) setTitle(labels[locale].UPLOAD_START_NEW_SEARCH)
    else setTitle(labels[locale].UPLOAD_SEARCH_BY_PHOTO)
  }, [])

  const uploadedItems = uploads.items.map((item, index) => (
    <UploadItem
      key={index}
      item={item}
      isMobile={isMobile}
      selected={index === selectedItem}
      setSelected={setSelectedItem}
    />
  ))

  if (isMobile && uploadedImage) {
    return (
      <Image src={uploadedImage} alt="Uploaded" contain blurredBackground />
    )
  }

  if (isMobile && mobilePreview) {
    return (
      <UploadPreview
        location={location}
        newPosition={getNewPosition(selectedItem, selectedProps)}
        items={uploads.items}
        fullsize={false}
        selectedItem={selectedItem}
        selectedProps={selectedProps}
        handlePropSelection={handlePropSelection}
        isMobile={isMobile}
      />
    )
  }

  if (isMobile && !mobilePreview) {
    return (
      <div className={classNames(styles.photoUploadPanel, cMobile)}>
        {uploads.items.length > 0 && !newSearch && (
          <>
            <div className={classNames(styles.uploadListContainer, cMobile)}>
              <h3>{labels[locale].UPLOAD_RECENT_UPLOADS}</h3>
              <div className={classNames(styles.uploadList, cMobile)}>
                {uploadedItems}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  if (!isMobile && uploadedImage && processingRef.current !== true) {
    return (
      <div
        className={styles.photoUploadPanel}
        data-testid="photo-upload-panel"
        role={ariaRoles.GROUP}
      >
        <div className={styles.newSearchPanel}>
          <DropArea
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
          />
          <div className={styles.buttonContainer}>
            <UploadCancelButton handleClick={handleCalcelSearchClick} />
            <StartProcessingButton handleClick={handleStartProcessingClick} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={styles.photoUploadPanel}
      data-testid="photo-upload-panel"
      role={ariaRoles.GROUP}
    >
      {uploads.items.length > 0 && !newSearch && (
        <>
          <div className={styles.uploadListContainer}>
            <NewSearchButton handleClick={handleNewSearchClick} />
            <h3>{labels[locale].UPLOAD_RECENT_UPLOADS}</h3>
            <div className={styles.uploadList}>{uploadedItems}</div>
          </div>
          <UploadPreview
            location={location}
            newPosition={getNewPosition(selectedItem, selectedProps)}
            items={uploads.items}
            fullsize={false}
            selectedItem={selectedItem}
            selectedProps={selectedProps}
            handlePropSelection={handlePropSelection}
          />
        </>
      )}
      {(newSearch || (uploads.items.length === 0 && !newSearch)) && (
        <div className={styles.newSearchPanel}>
          <DropArea
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
          />
          <div className={styles.buttonContainer}>
            {uploads.items.length > 0 && (
              <UploadCancelButton handleClick={handleCalcelSearchClick} />
            )}
          </div>
        </div>
      )}
      {processingRef.current === true && (
        <div className={styles.cover}>
          <Loader />
        </div>
      )}
    </div>
  )
}

PhotoUploadPanel.propTypes = {
  location: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  setTitle: PropTypes.func.isRequired,
}
