import React, { useRef } from "react"
import { useLocation } from "@reach/router"
import { historyWrapper } from "../utils/historyWrapper"
import classNames from "classnames"
import {
  Logo,
  Logotype,
  TextSearchField,
  PhotoUploadButton,
  Dialog,
  Dropdown,
} from "./index"
import { LandingHeaps } from "../features/landingHeaps/landingHeaps"
import LoginScreen from "../features/auth/LoginScreen"
import { locationToPosition } from "../utils/helpers"
import { useHasMounted } from "../hooks"
import useDropdown from "../features/dropdown/hooks/useDropdown"
import { useSelector } from "react-redux"

import "../styles/variables.css"
import * as styles from "./landing.module.css"

export const Landing = () => {
  const authToken = useSelector(state => state?.auth?.token)
  const [hasMounted] = useHasMounted()

  const livePage = typeof window === "object" && window.location

  const currentHref = useLocation().href
  const currentPathname = useLocation().pathname
  const currentSearch = useLocation().search

  const locationToRender = {
    href: livePage ? window.location.href : currentHref,
    pathname: livePage ? window.location.pathname : currentPathname,
    search: livePage ? window.location.search : currentSearch,
  }

  const dialogRef = useRef()
  const { searchTextRef, dropdownRef, openDropdown } = useDropdown()

  if (typeof window === "object" && !authToken)
    return (
      <div key={Date.now()}>
        <LoginScreen />
      </div>
    )

  return (
    <div
      key={hasMounted ? "dynamic" : "static"}
      className={styles.gasefiLanding}
    >
      <div
        className={classNames(styles.headContainer, {
          [styles.hiddenOnMobile]: openDropdown,
        })}
      >
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.sloganContainer}>
          <div className={styles.logotypeContainer}>
            <Logotype />
          </div>
          <h2>World's largest product catalog</h2>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.searchContainer}>
          <div
            className={classNames(styles.groupLabel, {
              [styles.hiddenOnMobile]: openDropdown,
            })}
          >
            <h3>Search</h3>
          </div>

          <div
            className={classNames(styles.searchOptions, {
              [styles.openOnMobile]: openDropdown,
            })}
          >
            <TextSearchField
              active={openDropdown !== null}
              landing
              ref={searchTextRef}
            />
            <div
              className={classNames(styles.searchButtonContainer, {
                [styles.hiddenOnMobile]: openDropdown,
              })}
            >
              <PhotoUploadButton location={locationToRender} landing />
            </div>
            {openDropdown && <Dropdown landing ref={dropdownRef} />}
          </div>
        </div>
        <div
          className={classNames(styles.discoverContainer, {
            [styles.hiddenOnMobile]: openDropdown,
          })}
        >
          <div className={styles.groupLabel}>
            <h3>Discover</h3>
          </div>
          <LandingHeaps />
        </div>
      </div>
      <div
        className={classNames(styles.footerContainer, {
          [styles.hiddenOnMobile]: openDropdown,
        })}
      />
      {historyWrapper.state.openDialog && (
        <Dialog
          location={locationToRender}
          position={locationToPosition(locationToRender)}
          dialogToDisplay={historyWrapper.state.openDialog}
          ref={dialogRef}
        />
      )}
    </div>
  )
}
