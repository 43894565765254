import React from "react"
import classNames from "classnames"
import useColorIndicator from "./hooks/useColorIndicator"
import * as styles from "./colorIndicator.module.css"

const ColorIndicator = ({ selected, colorCodes, colorImg }) => {
  const { backgroundStyle } = useColorIndicator({ colorCodes })
  return (
    <div
      className={classNames(styles.colorIndicator, {
        [styles.selected]: selected,
      })}
    >
      {colorImg != null && colorImg != "#" ? (
        <img src={colorImg} />
      ) : (
        <div style={backgroundStyle} />
      )}
    </div>
  )
}

export default ColorIndicator
