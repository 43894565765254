import React from "react"
import useProductCards from "./hooks/useProductCards"
import ProductCard from "./productCard/ProductCard"
import * as styles from "./productCards.module.css"

const ProductCards = () => {
  const { location, isLivePage, productsToDisplay } = useProductCards()
  return (
    <div className={styles.productCards}>
      {productsToDisplay.length > 0
        ? productsToDisplay.map((product, idx) => (
            <ProductCard
              key={`${product.id}-${idx}`}
              product={product}
              isLivePage={isLivePage}
              location={location}
              idx={idx}
            />
          ))
        : null}
    </div>
  )
}

export default ProductCards

/*
{heapsToDisplay.length > 0
  ? heapsToDisplay.map(heap => (
      <Heap
        key={heap.id}
        position={position}
        heap={heap}
        locale={locale}
      />
    ))
  : null}
*/
