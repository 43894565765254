import useNavigator from "../../../hooks/useNavigator"
import useProducts from "../../../hooks/useProducts"

const useProductCards = () => {
  const { location, isLivePage } = useNavigator()
  const { heapView, loadingMore } = useProducts({ location })

  // const heapsToDisplay = heapView.heaps
  const productsToDisplay = loadingMore
    ? heapView.products.concat({ id: "_dummy" })
    : heapView.products

  return { location, isLivePage, productsToDisplay }
}

export default useProductCards
