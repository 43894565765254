import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { connect } from "react-redux"
import { FilterButton } from "./index"
import { defaultLocale, labels } from "../utils/labelConstants"
import useNavigator from "../hooks/useNavigator"
import useFilters from "../features/filters/hooks/useFilters"
import { panelKeys } from "./index"
import * as styles from "./toolbox.module.css"

const Toolbox = forwardRef(({ openPanel, locale, productCount }, ref) => {
  const { location } = useNavigator()
  const { filterPanels: filterObj } = useFilters({ counts: false })
  useFilters({ caller: panelKeys.BRAND_FILTER, counts: false })

  const activeFilterPanels = Object.keys(
    filterObj.getActivePanels(openPanel)
  ).map(filterPanelKey => {
    return (
      <div
        key={filterPanelKey}
        className={classNames(styles.filterIcon, {
          [styles.filterIconSelected]: false,
        })}
      >
        <FilterButton
          location={location}
          openPanel={openPanel}
          filterPanel={filterObj.getPanel(filterPanelKey)}
        />
      </div>
    )
  })

  return (
    <div
      ref={ref}
      className={classNames(styles.toolbox, styles.mobileToolbox, {
        [styles.panelOpen]: openPanel,
      })}
    >
      <div className={styles.header}>{labels[locale].ACTIVE_FILTERS}</div>
      <div className={styles.filters}>{activeFilterPanels}</div>
    </div>
  )
})

const mapStateToProps = store => ({
  openPanel: store.history.state ? store.history.state.openPanel : null,
  locale: store.history.locale ? store.history.locale : defaultLocale,
  productCount: store.heapViewByPosition.productsToDisplayCount,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export const ToolboxRedux = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Toolbox)

Toolbox.propTypes = {
  location: PropTypes.object,
}
