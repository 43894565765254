import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import useLocale from "../hooks/useLocale"
import {
  CloseMobileDialogButton,
  ClosePanelButton,
  BackOneLevelButton,
  Selector,
  positionToLocation,
  hrefToPosition,
} from "./index"
import { Loader } from "./basicComponents/loader"
import { getPathnameString } from "../utils/positionUtils"
import { defaultLocale, labels } from "../utils/labelConstants"
import useNavigator from "../hooks/useNavigator"
import usePanel from "../hooks/usePanel"
import useProducts from "../hooks/useProducts"
import useOrigin from "../hooks/useOrigin"
import useFilters from "../features/filters/hooks/useFilters"

import * as styles from "./filterPanel.module.css"

export const FilterPanel = forwardRef((props, ref) => {
  const { location } = useNavigator()
  const { isPanelOpen: showFilterPanel, openPanel } = usePanel()
  const { filterPanels: filterObj, loadingFilterPanels: loading } = useFilters({
    counts: false,
  })
  const {
    loadingHeapView: loadingProducts,
    heapView,
    totalCount,
  } = useProducts({
    location,
  })
  const count = totalCount || 0

  const origin = useOrigin()
  const locale = useLocale()
  const buttonText = (labels[locale] ? labels[locale] : labels[defaultLocale])
    .BTN_SHOW_RESULTS

  return (
    <div
      ref={ref}
      className={classNames(styles.filterPanel, {
        [styles.hidden]: !showFilterPanel,
      })}
    >
      <div className={styles.header}>
        <div className={styles.mobileHeaderButton}>
          <BackOneLevelButton
            pathname={getPathnameString(
              positionToLocation(hrefToPosition(origin))
            )}
          />
        </div>
        <h3>{filterObj.getPanel(openPanel).name}</h3>
        <div className={styles.tabletHeaderButton}>
          <ClosePanelButton to={getPathnameString(location)} />
        </div>
      </div>
      {showFilterPanel && <Selector />}
      {loading && (
        <div className={styles.loadingCover}>
          <Loader />
        </div>
      )}
      <div className={styles.mobileFooter}>
        <CloseMobileDialogButton
          to={getPathnameString(location)}
          label={buttonText}
          count={count}
          loading={loadingProducts}
        />
      </div>
    </div>
  )
})

FilterPanel.propTypes = {
  location: PropTypes.object,
}
