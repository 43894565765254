const useColorIndicator = ({ colorCodes }) => {
  const darkenWhite = colorCode => {
    let result = colorCode
    if (
      colorCode &&
      (colorCode.toLowerCase() === "#ffffff" ||
        colorCode.toLowerCase() === "#fff")
    ) {
      result = "#F8F6F2"
    }
    return result
  }

  let backgroundStyle = {
    width: "100%",
    height: "100%",
  }

  if (Array.isArray(colorCodes)) {
    if (colorCodes.length === 1) {
      backgroundStyle.backgroundColor = darkenWhite(colorCodes[0])
    } else if (colorCodes.length > 1) {
      const numberOfColors = colorCodes.length
      var backgroundString = "linear-gradient(90deg "
      colorCodes.map((colorCode, colorCodeIndex) => {
        backgroundString = `${backgroundString}, ${darkenWhite(colorCode)} ${
          (100 / numberOfColors) * colorCodeIndex
        }%, ${darkenWhite(colorCode)} ${
          (100 / numberOfColors) * (colorCodeIndex + 1)
        }%`
        return false
      })
      backgroundString = backgroundString + ")"
      backgroundStyle.background = backgroundString
    }
  }

  return { backgroundStyle }
}

export default useColorIndicator
