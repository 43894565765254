import { useState, useRef, useEffect } from "react"
import useVisualSelector from "./useVisualSelector"

const useProductImage = ({ product, idx }) => {
  const [isInView, setIsInView] = useState()
  const [imageLoaded, setImageLoaded] = useState()
  const cardRef = useRef()
  const imgRef = useRef()
  const { selectedVisualIdx: selectedIndex } = useVisualSelector(product)

  const checkInView = () => {
    const rect = cardRef.current ? cardRef.current.getBoundingClientRect() : {}
    let inView = false
    if (typeof window === "object") {
      inView = rect.top - 400 < window.innerHeight && rect.bottom >= -200
    }
    return inView
  }

  const inView = checkInView()

  const resize = () => {
    setIsInView(state => {
      const inView = checkInView()
      return state !== inView ? inView : state
    })
  }

  useEffect(() => {
    setIsInView(state => {
      const inView = checkInView()
      return state !== inView ? inView : state
    })
    setImageLoaded(state => {
      const loaded = imgRef.current?.clientWidth > 0
      return state !== loaded ? loaded : state
    })
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  useEffect(() => {
    setIsInView(state => {
      const inView = checkInView()
      return state !== inView ? inView : state
    })
    setImageLoaded(state => {
      const loaded = imgRef.current?.clientWidth > 0
      return state !== loaded ? loaded : state
    })
  }, [inView])

  const handleImageLoaded = () => {
    setImageLoaded(state => (state !== true ? true : state))
  }

  return {
    cardRef,
    imgRef,
    handleImageLoaded,
    selectedIndex,
    isInView,
    imageLoaded,
  }
}

export default useProductImage
