import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { LandingHeapPreview } from "./landingHeapPreview"
import useProducts from "../../hooks/useProducts"
import getRoundedNumber from "../../utils/getRoundedNumber"
import * as styles from "./landingHeaps.module.css"

export const LandingHeaps = () => {
  const data = useStaticQuery(graphql`
    query LandingHeaps {
      allLandingPageHeapDataJson {
        edges {
          node {
            link
            caption
            count
            imgData {
              top
              left
              angle
              url
            }
          }
        }
      }
    }
  `)

  const landingHeaps = data.allLandingPageHeapDataJson.edges
  const { totalCount: footwearCount } = useProducts()

  return (
    <div className={styles.landingHeaps}>
      {landingHeaps.map(
        ({ node: { caption, count, link, imgData } }, heapIndex) => {
          const heap = (
            <div className={styles.landingHeap}>
              <LandingHeapPreview caption={caption} imgData={imgData} />
              <h4>{caption}</h4>
              {caption === "Footwear" && footwearCount != null ? (
                <p>{`${getRoundedNumber(footwearCount)} products`}</p>
              ) : (
                <p>{count}</p>
              )}
            </div>
          )
          if (link)
            return (
              <div key={heapIndex} className={styles.landingHeapWrapper}>
                <Link to={link}>{heap}</Link>
              </div>
            )
          return (
            <div key={heapIndex} className={styles.landingHeapWrapper}>
              {heap}
            </div>
          )
        }
      )}
    </div>
  )
}
