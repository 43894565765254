import { useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setScroll, selectOffset } from "../store/globalState"

const useScroll = () => {
  const dispatch = useDispatch()
  const timeoutRef = useRef()

  const offsetRef = useRef()
  const globalOffsetRef = useRef()

  const offset = useSelector(selectOffset)
  globalOffsetRef.current = offset

  const handleScroll = event => {
    offsetRef.current = event.currentTarget.scrollTop
    if (timeoutRef.current == null) {
      if (offsetRef.current !== globalOffsetRef.current) {
        dispatch(setScroll(offsetRef.current))
      }
      if (offsetRef.current !== 0) {
        setTimeout(() => {
          if (offsetRef.current !== globalOffsetRef.current) {
            dispatch(setScroll(offsetRef.current))
          }
        }, 100)
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null
          if (offsetRef.current !== globalOffsetRef.current) {
            dispatch(setScroll(offsetRef.current))
          }
        }, 200)
      }
    }
  }

  return { offset, handleScroll }
}

export default useScroll
