import React from "react"
import classNames from "classnames"
import { Loader, SafeLink, safeLinkActions } from "../../../components/index"
import ProductImage from "./ProductImage"
import ProductInfo from "./ProductInfo"

import * as styles from "./productCard.module.css"
import * as safeLinkStyles from "../../../components/safeLink.module.css"

const ProductCard = ({ product, location, idx }) => {
  if (product.id === "_dummy") {
    return (
      <div className={classNames(styles.productCard, styles.loaderCard)}>
        <Loader />
      </div>
    )
  }

  const touchCard = (
    <div className={classNames(styles.productCard, styles.hoverable)}>
      <ProductImage product={product} idx={idx} />
      <ProductInfo product={product} />
      <div className={styles.linkContainer}>
        <SafeLink
          to={product.getDetailsUrl(location)}
          action={safeLinkActions.MORE_OPTIONS}
          className={safeLinkStyles.noUnderline}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  )

  return <>{touchCard}</>
}

export default ProductCard
