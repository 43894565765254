import { useSelector, useDispatch } from "react-redux"
import { sortingNameByIndex } from "../components"
import {
  setSortingIndex,
  selectSortingIndex,
} from "../features/products/productsSlice"

const useSorting = () => {
  const dispatch = useDispatch()
  const sortingIndex = useSelector(selectSortingIndex)

  const setIndex = index => {
    dispatch(setSortingIndex(index))
  }

  const sortingOrder = sortingNameByIndex(sortingIndex || 0)

  return {
    sortingIndex,
    setSortingIndex: setIndex,
    sortingOrder,
  }
}

export default useSorting
