import { useSelector, useDispatch } from "react-redux"
import {
  SafeLinkObj,
  safeLinkActions,
  getPathnameString,
  locationDeleteParam,
  historyWrapper,
} from "../utils"
import { pushHistory } from "../store"
import useSearchParams from "./useSearchParams"

const useDialog = () => {
  const dispatch = useDispatch()
  let openDialog = useSelector(store => {
    return store.history && store.history.state
      ? store.history.state.openDialog
      : null
  })
  const { getParam } = useSearchParams()
  const openProduct = getParam("p")

  if (openProduct != null) {
    if (openDialog == null) {
      openDialog = "moreOptions"
    }
  }

  const setOpenDialog = () => {}

  const closeDialog = () => {
    const safeLinkObj = new SafeLinkObj(
      safeLinkActions.CLOSE_DIALOG,
      { panelId: historyWrapper.state.openPanel },
      historyWrapper.state,
      historyWrapper.location
    )
    const newState = safeLinkObj.newState
    const closeUrl = getPathnameString(
      locationDeleteParam(historyWrapper.location, "p")
    )
    historyWrapper.pushState(newState, "", closeUrl)
    dispatch(pushHistory())
  }

  return {
    openDialog,
    isDialogOpen: openDialog != null,
    closeDialog,
    setOpenDialog,
  }
}

export default useDialog
