// extracted by mini-css-extract-plugin
export var cMobile = "uploadPreview-module--cMobile--aDfyy";
export var fullSize = "uploadPreview-module--fullSize--t93ys";
export var imageContainer = "uploadPreview-module--imageContainer--t8x-n";
export var infoContainer = "uploadPreview-module--infoContainer--LtTkd";
export var loaderContainer = "uploadPreview-module--loaderContainer--rVSbn";
export var processing = "uploadPreview-module--processing--vT10j";
export var processingMessage = "uploadPreview-module--processingMessage--XtOfo";
export var processingResults = "uploadPreview-module--processingResults--iTujs";
export var propsContainer = "uploadPreview-module--propsContainer--ABTmk";
export var uploadPreview = "uploadPreview-module--uploadPreview--o3YVr";