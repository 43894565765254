import { useSearchQuery } from "../keywordSearchSlice"
import useVersioning from "../../../hooks/useVersioning"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { setSearchText } from "../../../store/globalState"

const useKeywordSearch = () => {
  const { deploymentVersion } = useVersioning()
  const searchText = useSelector(state => state.globalState.searchText)
  const dispatch = useDispatch()
  const keywords = useSearchQuery({
    text: searchText,
    deployment_id: deploymentVersion,
  })

  const setText = text => {
    dispatch(setSearchText(text))
  }

  return {
    searchText,
    setSearchText: setText,
    keywords: keywords.data,
    keywordsLoading: keywords.isLoading,
  }
}

export default useKeywordSearch
