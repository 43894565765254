import { FilterPanelObj } from "./filterPanelObj"
import { panelKeys, multicolorOptions } from "../utils/constants"
import { getOrderedPanelNames } from "../features/filters/utils/getOrderedPanelNames"
import { createPanelData } from "../features/filters/utils/createPanelData"
import { createAddFilterPanel } from "../features/filters/utils/createAddFilterPanel"
import { getFiltribute } from "../features/filters/utils/getFiltribute"
import { getCollections } from "../features/filters/utils/getCollections"
import { parseData } from "../features/filters/utils/mergePanels"

export class FilterObj {
  constructor(data, fromCache) {
    // >> - Was added for development. Probably should be removed later
    /*
    if (!_data._filter_panels) {
      this._allPanelNames = []
      this._activePanelNames = []
      this._availablePanelNames = []
      this._panelData = []
      this._loading = false
      return
    }
    */
    this._allPanelNames = []
    this._activePanelNames = []
    this._availablePanelNames = []
    this._panelData = []
    this._loading = false
    if (this.isInputDataValid(data)) {
      const [
        orderedData,
        allPanelNames,
        activePanelNames,
        availablePanelNames,
      ] = parseData(data, data._coord)
      if (orderedData !== null) {
        this._allPanelNames = allPanelNames
        this._activePanelNames = activePanelNames
        this._availablePanelNames = availablePanelNames
        this._panelData = {}
        this._allPanelNames.map(panelName => {
          this._panelData[panelName] = createPanelData(
            orderedData,
            panelName,
            fromCache
          )
          return false
        })
        this._panelData[panelKeys.ADD_FILTER] = createAddFilterPanel(
          this._availablePanelNames.map(
            panelName => this._panelData[panelName]
          ),
          this._activePanelNames.map(panelName => this._panelData[panelName])
        )
        this._activePanelNames = [panelKeys.ADD_FILTER].concat(
          this._activePanelNames
        )
      }
    }
  }

  isInputDataValid = data => {
    if (data == null) return false
    if (Array.isArray(data)) return false
    return true
  }

  getFilterPanels = (data, panelNames) => {
    if (data == null) return {}
    if (
      panelNames === null ||
      typeof panelNames === "undefined" ||
      !Array.isArray(panelNames)
    )
      return {}
    var result = {}

    panelNames.map(key => {
      result[key] = data[key]
      return false
    })
    return result
  }

  getFiltribute = getFiltribute
  getCollections = getCollections

  isPanelActive = panelName => {
    return this._activePanelNames.indexOf(panelName) >= 0
  }

  isPanelAvailable = panelName => {
    return this._availablePanelNames.indexOf(panelName) >= 0
  }

  getActivePanels = openPanel => {
    if (!this.isPanelAvailable(openPanel)) return this.activeFilterPanels
    else {
      getOrderedPanelNames([...this._activePanelNames, openPanel])
      return this.getFilterPanels(
        this._panelData,
        getOrderedPanelNames([...this._activePanelNames, openPanel])
      )
    }
  }

  set loading(isLoading) {
    this._loading = isLoading
  }

  get loading() {
    return this._loading
  }

  get filterPanels() {
    return this.getFilterPanels(this._panelData, this._allPanelNames)
  }
  get activeFilterPanels() {
    var result = this.getFilterPanels(this._panelData, this._activePanelNames)
    return result
  }
  get availableFilterPanels() {
    return this.getFilterPanels(this._panelData, this._availablePanelNames)
  }
  getPanel = panelKey => {
    var result = null
    const panelData = this.getFilterPanels(this._panelData, [panelKey])
    if (typeof panelData[panelKey] === "undefined")
      result = new FilterPanelObj(createPanelData(null, panelKey))
    else result = new FilterPanelObj(panelData[panelKey])
    return result
  }
}
