import React, { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import useIsTouchable from "../../hooks/useIsTouchable"
import useLocale from "../../hooks/useLocale"
import useSorting from "../../hooks/useSorting"
import { sortingNames } from "../../components/index"

import {
  Icon,
  icons,
  defaultLocale,
  labels,
  groupLabels,
  sortingLabels,
} from "../../components/index"

import * as styles from "./resultsDropdown.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"

export const ResultsDropdown = ({ grouping = false, sorting = false }) => {
  const options = Object.keys(sortingNames).map(
    sortingKey => sortingNames[sortingKey]
  )
  const { sortingIndex: selectedOption, setSortingIndex: setSelectedOption } =
    useSorting()

  const isTouchScreen = useIsTouchable()
  const [isOpen, _setIsOpen] = useState(false)
  const locale = useLocale()

  const setIsOpen = value => {
    isOpenRef.current = value
    _setIsOpen(value)
  }

  const buttonRef = useRef()
  const itemsRef = useRef()
  const isOpenRef = useRef(false)

  useEffect(() => {
    if (options.length > 1) document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const handleClick = event => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      setIsOpen(!isOpenRef.current)
    } else if (itemsRef.current && !itemsRef.current.contains(event.target)) {
      if (isOpenRef.current) setIsOpen(false)
    }
  }

  const handleDropdownSelect = selection => {
    setSelectedOption(selection)
    if (isOpenRef.current) setIsOpen(false)
  }

  const dropdownLabels = options.map((option, index) => {
    const labelKey = grouping
      ? groupLabels[option]
      : sorting
      ? sortingLabels[option]
      : "EMPTY"

    return (
      <div
        key={index}
        className={classNames(
          labelStyles.resultHeaderDropdown,
          styles.dropdownItem
        )}
        onClick={() => handleDropdownSelect(index)}
      >
        {labels[locale ? locale : defaultLocale][labelKey]}
      </div>
    )
  })

  const labelKey = grouping
    ? groupLabels[options[selectedOption]]
    : sorting
    ? sortingLabels[options[selectedOption]]
    : "EMPTY"

  return (
    <div
      className={classNames(
        styles.dropdownContainer,
        { [styles.enabled]: options.length > 1 },
        { [styles.disabled]: options.length <= 1 }
      )}
    >
      <div
        className={classNames(
          styles.roundedDropdown,
          styles.greyDropdown,
          { [styles.hoverable]: !isTouchScreen },
          { [styles.open]: isOpen },
          { [styles.closed]: !isOpen },
          { [styles.enabled]: options.length > 1 },
          { [styles.disabled]: options.length <= 1 }
        )}
        ref={buttonRef}
      >
        <span className={labelStyles.resultHeaderDropdown}>
          {labels[locale ? locale : defaultLocale][labelKey]}
        </span>
        <Icon icon={icons.arrowDown} />
      </div>
      {isOpen ? (
        <div
          className={classNames(styles.dropdownItems, styles.greyDropdown, {
            [styles.open]: isOpen,
          })}
          ref={itemsRef}
        >
          {dropdownLabels}
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
    </div>
  )
}
