import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import useKeywordSearch from "../features/keywordSearch/hooks/useKeywordSearch"
import useErrors from "../hooks/useErrors"
import useScroll from "../hooks/useScroll"

import {
  FilterPanel,
  Header,
  Toolbox,
  Dialog,
  Dropdown,
  // TextBasedNavigation,
  UploadsObj,
  // fetchPhotoSearchRequest,
  screenWidthBreakPointForFilterPanelInMobile,
  dialogNames,
  useHasMounted,
  setIsMobile,
  Loader,
  historyWrapper,
  // LoginScreen,
} from "./index"
import { Results } from "../features/products/Results"
import ErrorHandler from "../features/errorHandler/ErrorHandler"
import LoginScreen from "../features/auth/LoginScreen"

import useNavigator from "../hooks/useNavigator"
import { serializePosition } from "../utils/positionUtils"
// import useSorting from "../hooks/useSorting"
// import useGrouping from "../hooks/useGrouping"
import useProducts from "../hooks/useProducts"
import usePanel from "../hooks/usePanel"
import useDialog from "../hooks/useDialog"
import { useSelector } from "react-redux"

import { gasefiApp, loadingCover } from "./app.module.css"

const App = ({
  // staticPathname,
  homeDir,
  dispatch,
  historyFromRedux,
  photoSearch,
}) => {
  useErrors()
  const { offset, handleScroll: setScroll } = useScroll()
  const authToken = useSelector(state => state?.auth?.token)
  const navigator = useNavigator()

  const appRef = useRef({})
  const toolboxRef = useRef()
  const filterPanelRef = useRef()
  const resultsRef = useRef()
  const dialogRef = useRef()
  const dropdownRef = useRef()
  const searchTextRef = useRef()
  const scrollTimoutRef = useRef(false)
  /*
  const { sortingIndex, setSortingIndex } = useSorting()
  const sortingIndexRef = useRef()
  sortingIndexRef.current = sortingIndex
  const { groupingIndex, setGroupingIndex } = useGrouping()
  const groupingIndexRef = useRef()
  groupingIndexRef.current = groupingIndex
*/
  const [hasMounted] = useHasMounted()
  const [openDropdown, setOpenDropdown] = useState(null)
  // const [searchText, setSearchText] = useState("")
  const [uploads, _setUploads] = useState()
  const uploadsRef = useRef()
  const { searchText, setSearchText } = useKeywordSearch()
  const setUploads = uploadsObj => {
    uploadsRef.current = uploadsObj
    _setUploads(uploadsObj)
  }

  const { heapView, loadingHeapView, loadMoreProducts } = useProducts({
    location: navigator.location,
    caller: "app",
  })
  const heapViewRef = useRef()
  heapViewRef.current = heapView

  const { isPanelOpen } = usePanel()
  const { isDialogOpen, openDialog, closeDialog } = useDialog()

  historyWrapper.verifyState()
  navigator.saveState()

  if (navigator.isLivePage) {
    var isMobile = true
    if (window.innerWidth > screenWidthBreakPointForFilterPanelInMobile)
      isMobile = false
    if (isMobile !== historyFromRedux.isMobile) dispatch(setIsMobile(isMobile))
  }

  const position = navigator.position
  const serializedPosition = serializePosition(position)
  let timeoutId

  const handleScroll = () => {
    if (appRef.current && resultsRef.current) {
      if (
        appRef.current.scrollTop >
        resultsRef.current.offsetHeight - appRef.current.offsetHeight * 3
      ) {
        loadMoreProducts()
      }
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line
  }, [])
  /*
  useEffect(() => {
    if (
      appRef.current !== null &&
      typeof appRef.current.addEventListener === "function"
    )
      appRef.current.addEventListener("scroll", handleScroll)
    return () => {
      if (appRef.current !== null)
        appRef.current.removeEventListener("scroll", handleScroll)
    }
    // eslint-disable-next-line
  }, [appRef.current])
*/

  useEffect(() => {
    handleScroll()
  }, [offset])

  useEffect(() => {
    if (!photoSearch) {
      return false
    }
    setUploads(new UploadsObj(photoSearch))
    // eslint-disable-next-line
  }, [photoSearch])

  const closeDropdown = () => {
    setSearchText("")
    setOpenDropdown(state => (state != null ? null : state))
  }

  useEffect(() => {
    appRef.current.scrollTop = 0
    closeDropdown()
    // eslint-disable-next-line
  }, [serializedPosition])

  const handleClick = event => {
    if (dropdownRef.current) {
      if (!dropdownRef.current.contains(event.target)) {
        if (
          searchTextRef.current &&
          !searchTextRef.current.contains(event.target)
        )
          closeDropdown()
      }
    } else if (dialogRef.current) {
      if (!dialogRef.current.contains(event.target)) closeDialog()
    }
  }

  useEffect(() => {
    if (searchText && openDropdown !== "textSearch") {
      setOpenDropdown("textSearch")
    } else if (!searchText && openDropdown) {
      closeDropdown()
    }
    // eslint-disable-next-line
  }, [searchText])

  const isDropdownOpen = openDropdown !== null

  if (navigator.isLivePage) {
    document.body.style.overflow = (
      isMobile ? isDialogOpen || isPanelOpen || isDropdownOpen : isDialogOpen
    )
      ? "hidden"
      : "initial"
  }

  if (typeof window === "object" && !authToken)
    return (
      <div key={Date.now()}>
        <LoginScreen />
      </div>
    )

  return (
    <div
      className={gasefiApp}
      key={hasMounted ? "dynamic" : "static"}
      ref={appRef}
      onScroll={setScroll}
    >
      <Results ref={resultsRef} />
      {navigator.isLivePage && loadingHeapView && (
        <div className={loadingCover}>
          <Loader />
        </div>
      )}
      <Toolbox ref={toolboxRef} />
      {isPanelOpen ? (
        <FilterPanel ref={filterPanelRef} />
      ) : (
        <div style={{ display: "none" }} />
      )}
      <Header
        homeDir={homeDir}
        isDropdownOpen={isDropdownOpen}
        ref={searchTextRef}
      />
      {isDialogOpen && openDialog === dialogNames.MORE_OPTIONS ? (
        <Dialog data={heapView} ref={dialogRef} />
      ) : (
        <div style={{ display: "none" }} />
      )}
      {openDialog && openDialog === dialogNames.UPLOADS && (
        <Dialog data={uploads} ref={dialogRef} />
      )}
      {openDropdown && <Dropdown searchText={searchText} ref={dropdownRef} />}
      <ErrorHandler />
    </div>
  )
}

/*
      <TextBasedNavigation 
        location={navigator.location}
        filterPanels={filterPanels}
      />
*/

const mapStateToProps = store => {
  return store => {
    return {
      historyFromRedux: store.history,
      photoSearch: store.photoSearch,
    }
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

export const AppRedux = connect(mapStateToProps, mapDispatchToProps)(App)

App.propTypes = {
  staticData: PropTypes.object,
  staticPathname: PropTypes.string,
}
