import React from "react"
import { List, TextInput } from "../../../components/index.js"
import BrandFiltribute from "./BrandFiltribute.jsx"
import useNavigator from "../../../hooks/useNavigator.js"
import useBrandSelector from "./hooks/useBrandSelector.js"

import * as styles from "../../../components/selectors/selector.module.css"

export const BrandSelector = () => {
  const {
    textFilterValue,
    setTextFilterValue,
    activeFiltributes,
    availableFiltributes,
  } = useBrandSelector()
  const { position } = useNavigator()

  const activeSelectionList = activeFiltributes.map(selection => (
    <BrandFiltribute
      key={selection.id}
      filtribute={selection}
      position={position}
    />
  ))
  const selectionList = availableFiltributes.map(selection => (
    <BrandFiltribute
      key={selection.id}
      filtribute={selection}
      position={position}
    />
  ))

  return (
    <>
      {activeSelectionList.length > 0 ? (
        <List>{activeSelectionList}</List>
      ) : (
        <div style={{ display: "none" }} />
      )}
      <div style={{ width: "100%", marginBottom: "12px" }}>
        <TextInput value={textFilterValue} onChange={setTextFilterValue} />
      </div>
      <div className={styles.scrollingList}>
        <List>
          {selectionList.length > 0 ? (
            selectionList
          ) : (
            <div style={{ display: "none" }} />
          )}
        </List>
      </div>
    </>
  )
}
