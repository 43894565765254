import { createSlice } from "@reduxjs/toolkit"

const TOKEN =
  typeof window === "object" ? window.localStorage.getItem("gasefiToken") : null

const authSlice = createSlice({
  name: "auth",
  initialState: { token: TOKEN || null },
  reducers: {
    setToken: (state, action) => {
      const token = action.payload
      state.token = token
      if (typeof window === "object") {
        window.localStorage.setItem("gasefiToken", token)
      }
    },
    discardToken: state => {
      state.token = null
      if (typeof window === "object") {
        window.localStorage.removeItem("gasefiToken")
      }
    },
  },
})

export const { setToken, discardToken } = authSlice.actions

export default authSlice.reducer
