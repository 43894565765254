import { historyActionTypes } from "./historyActions"
import { storeReducers, storeReducerVersions } from "../../utils/constants"
import { defaultLocale } from "../../utils/labelConstants"

export const initialState = {
  location: {
    pathname: undefined,
    search: undefined,
  },
  state: {
    openPanel: null,
    openDialog: null,
    openDropdown: null,
    origin: null,
    level: 0,
    key: String(Date.now()),
  },
  timestamp: null,
  targetLevel: 0,
  locale: defaultLocale,
  isMobile: true,
  version: storeReducerVersions[storeReducers.HISTORY],
}

export const historyReducer = (state = initialState, action = {}) => {
  var newState = {}
  switch (action.type) {
    case historyActionTypes.HISTORY_PUSH:
      return {
        ...state,
        timestamp: action.payload.timestamp,
      }

    case historyActionTypes.HISTORY_SAVE:
      newState = {
        ...state,
        location: action.payload.location,
        state: action.payload.state,
        targetLevel: action.payload.state.level,
      }
      return newState

    case historyActionTypes.HISTORY_LEVEL_DECREASE:
      newState = {
        ...state,
        targetLevel:
          typeof action.payload.level !== "undefined"
            ? action.payload.level
            : Math.max(state.state.level ? state.state.level - 1 : 0, 0),
      }
      return newState

    case historyActionTypes.HISTORY_IS_MOBILE_SET:
      return {
        ...state,
        isMobile: action.payload.isMobile,
      }

    case historyActionTypes.HISTORY_SORTING_SET:
      return {
        ...state,
        sorting: action.payload.sorting,
      }

    case historyActionTypes.HISTORY_GROUPING_SET:
      return {
        ...state,
        grouping: action.payload.grouping,
      }

    case historyActionTypes.HISTORY_TOUCHABLE_SET:
      return {
        ...state,
        touchable: action.payload.touchable,
      }

    default:
      return state
  }
}
