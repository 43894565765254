import { useRef, useEffect } from "react"
import { getProductIdFromLocation } from "../../../utils/positionUtils"
import useIsTouchable from "../../../hooks/useIsTouchable"
import useNavigator from "../../../hooks/useNavigator"
import { useGetProductQuery } from "../../products/productsApiSlice"
import useVersioning from "../../../hooks/useVersioning"

const useMoreOptionsPanel = ({ heapView, setTitle }) => {
  const isTouchScreen = useIsTouchable()
  const { location } = useNavigator()
  const currentProductId = getProductIdFromLocation(location)
  const productIdRef = useRef()
  const product = heapView.getProduct(currentProductId)
  const { deploymentVersion } = useVersioning()
  const detailsLoadingRef = useRef()
  const previousProduct = heapView.getPreviousProduct(currentProductId)
  const nextProduct = heapView.getNextProduct(currentProductId)
  const productDetails = useGetProductQuery(
    { id: currentProductId, deploymentId: deploymentVersion },
    { skip: currentProductId == null }
  )

  useEffect(() => {
    if (product) {
      if (
        !productIdRef ||
        productIdRef.current === "undefined" ||
        productIdRef.current !== currentProductId
      ) {
        productIdRef.current = currentProductId
        setTitle(product.brand + " " + product.name)
      }
    }
  }, [product])

  var productArray = [product]
  if (previousProduct) productArray = [previousProduct].concat(productArray)
  if (nextProduct) productArray = productArray.concat(nextProduct)

  if (product != null && productDetails?.data != null) {
    detailsLoadingRef.current = false
    product.addDetails(productDetails?.data)
  }

  return {
    product,
    nextProduct,
    previousProduct,
    productArray: productArray || [],
    detailsLoading: productDetails ? productDetails.isLoading : true,
    isTouchScreen,
  }
}

export default useMoreOptionsPanel
