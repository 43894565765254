import React from "react"
import useLoginScreen from "./hooks/useLoginScreen"
import { Logo } from "../../components"
import { Logotype } from "../../components"
import {
  cLoginScreen,
  cLoginPanel,
  cLoginLabel,
  cLoginButton,
} from "./loginScreen.module.css"
import { cTextInput } from "../../components/selectors/textInput.module.css"

const LoginScreen = () => {
  const {
    handleLoginClick,
    authenticating,
    loginName,
    setLoginName,
    loginPass,
    setLoginPass,
    authError,
  } = useLoginScreen()

  return (
    <div className={cLoginScreen}>
      <div className={cLoginPanel}>
        <div
          style={{
            height: "50px",
            marginBottom: "40px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Logo />
          <Logotype />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <label htmlFor="nameInput" className={cLoginLabel}>
              User Name
            </label>
            <input
              id="nameInput"
              type="text"
              value={loginName}
              onChange={e => {
                setLoginName(e.currentTarget.value)
              }}
              className={cTextInput}
              style={{ border: "1px solid var(--clr-grey-l1)" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <label htmlFor="passInput" className={cLoginLabel}>
              Password
            </label>
            <input
              id="passInput"
              type="password"
              value={loginPass}
              onChange={e => {
                setLoginPass(e.currentTarget.value)
              }}
              autoComplete="on"
              className={cTextInput}
              style={{ border: "1px solid var(--clr-grey-l1)" }}
            />
          </div>
          {!authenticating && authError && (
            <p
              style={{
                color: "red",
                textAlign: "center",
                fontSize: "var(--font-size-main)",
              }}
            >
              {authError}
            </p>
          )}
          {authenticating ? (
            <p
              style={{ textAlign: "center", fontSize: "var(--font-size-main)" }}
            >
              Authenticating...
            </p>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button onClick={handleLoginClick} className={cLoginButton}>
                Login
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default LoginScreen
