import React from "react"
import classNames from "classnames"
import {
  nameExceptions,
  commonNames,
  insoleColors,
} from "../../utils/constants"
import * as styles from "./visualization.module.css"

const TEXT = "text"
const ASSET = "asset"
const VISIBLE = 9
const VISIBLE_IN_SMALL = 7
const VISIBLE_IN_MEDIUM = 5
const VISIBLE_IN_LARGE = 3

const TextVisualization = ({ visualization }) => {
  const getVisibleSting = text => {
    const textArray = text.split(" ")
    let visibleString = text
    if (nameExceptions[text] != null) {
      visibleString = nameExceptions[text]
    } else {
      const byIdx = textArray.indexOf("by")
      if (byIdx > 0) {
        const designer = textArray.slice(byIdx + 1, textArray.length)
        let initials
        if (designer.length === 1) {
          initials = `by\xa0${designer}`
        } else {
          initials = `by\xa0${designer.map(item => item[0]).join("")}`
        }
        let newArray = textArray.slice(0, byIdx)
        newArray.push(initials)
        return newArray.join(" ")
      }
    }
    if (textArray.length > 1 && text.length < VISIBLE * 2) {
      return visibleString
    } else if (textArray.length > 1) {
      if (textArray.length < 4) {
        const newArray = textArray.map(item => item.slice(0, VISIBLE))
        return newArray.join(" ")
      }
      if (
        commonNames.indexOf(textArray[0]) !== -1 &&
        visibleString.length > VISIBLE
      ) {
        textArray[0] = textArray[0][0]
        visibleString = textArray.join(" ")
        if (visibleString.length > VISIBLE) {
          visibleString = textArray.slice(1, textArray.length).join(" ")
        }
      }
      if (textArray.length == 2) {
        if (textArray[0] === VISIBLE_IN_SMALL) {
          visibleString = textArray[0]
        }
        if (textArray[0] === VISIBLE_IN_MEDIUM) {
          visibleString = textArray[0]
        }
      } else if (textArray.length > 2) {
        if (textArray.slice(0, 2).join(" ") === VISIBLE_IN_SMALL) {
          visibleString = textArray.slice(0, 2).join(" ")
        }
        if (textArray.slice(0, 2).join(" ") === VISIBLE_IN_MEDIUM) {
          visibleString = textArray.slice(0, 2).join(" ")
        }
      }
    }
    if (visibleString.length > VISIBLE)
      visibleString = visibleString.slice(0, VISIBLE)
    return visibleString
  }
  const visibleString = getVisibleSting(visualization.value)

  const getColorIndexFromString = string => {
    {
      var hash = 0,
        i,
        chr
      if (string.length === 0) return hash
      if (typeof string !== "string") return hash
      for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
      }
      return (Math.sign(hash) * hash) % insoleColors.length
    }
  }

  return (
    <div
      key={`${visualization.value.replaceAll(" ", "_")}_${visualization.idx}`}
      className={classNames(styles.visualization, styles.text, {
        [styles.store]: visualization.panel === "store",
      })}
      style={{
        backgroundColor:
          visualization.panel !== "store"
            ? insoleColors[getColorIndexFromString(visibleString)]
            : "",
      }}
    >
      <p
        className={classNames(styles.visualizationText, {
          [styles.small]: visibleString.length <= VISIBLE_IN_SMALL,
          [styles.medium]: visibleString.length <= VISIBLE_IN_MEDIUM,
          [styles.large]: visibleString.length <= VISIBLE_IN_LARGE,
        })}
      >
        {visibleString}
      </p>
    </div>
  )
}

const AssetVisualization = ({ visualization }) => {
  return (
    <div
      key={`${visualization.id}_${visualization.idx}`}
      className={classNames(styles.visualization, styles.asset)}
    >
      <img
        className={styles.visualizationAsset}
        src={visualization.url}
        alt={visualization.caption}
      />
    </div>
  )
}

const Visualization = ({ visualization }) => {
  if (visualization == null) return null
  if (visualization.type) {
    if (visualization.type === TEXT)
      return <TextVisualization visualization={visualization} />
    if (visualization.type === ASSET)
      return <AssetVisualization visualization={visualization} />
  }
  if (visualization.url) {
    return <AssetVisualization visualization={visualization} />
  }
  if (typeof visualization.value === "string") {
    return <TextVisualization visualization={visualization} />
  }
  return null
}

export default Visualization
