import React from "react"
import ColorOptions from "./ColorOptions"
import useNavigator from "../../../hooks/useNavigator"
import * as styles from "./productInfo.module.css"
import * as labelStyles from "../../../styles/labelStyles.module.css"

const ProductInfo = ({ product }) => {
  var price = product.price
  return (
    <div className={styles.infoContainer}>
      <ColorOptions product={product} />
      <h4 className={labelStyles.productCaption}>
        {product.brand + (product.name ? " " + product.name : "")}
      </h4>
      <div className={styles.priceInfo}>
        {typeof price === "number" && <p>{`$${price}`}</p>}
      </div>
    </div>
  )
}

export default ProductInfo