import { useLocation, useNavigate } from "@reach/router"

const useSearchParams = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const getParam = paramName => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get(paramName)
  }

  const getParams = paramName => {
    return searchParams.getAll(paramName)
  }

  const setParam = (paramName, paramValue) => {
    const newSearchParams = new URLSearchParams()
    newSearchParams.set(paramName, paramValue)
    const updatedSearchParamsString = newSearchParams.toString()
    navigate(`${location.pathname}?${updatedSearchParamsString}`)
  }

  const setParams = params => {
    const newSearchParams = new URLSearchParams()
    let paramKeys = Object.keys(params)
    if (Array.isArray(paramKeys)) {
      let updatedSearchParamsString
      paramKeys?.map(paramKey => {
        if (params[paramKey] != null) {
          newSearchParams.set(paramKey, params[paramKey])
          updatedSearchParamsString = newSearchParams.toString()
        }
        return false
      })
      navigate(`${location.pathname}?${updatedSearchParamsString}`)
    }
  }

  const addParam = (paramName, paramValue, replace) => {
    searchParams.set(paramName, paramValue)
    const updatedSearchParamsString = searchParams.toString()
    navigate(`${location.pathname}?${updatedSearchParamsString}`, {
      replace: replace,
    })
  }

  const addParams = params => {
    let paramKeys = Object.keys(params)
    if (Array.isArray(paramKeys)) {
      let updatedSearchParamsString
      paramKeys?.map(paramKey => {
        if (params[paramKey] != null) {
          searchParams.set(paramKey, params[paramKey])
          updatedSearchParamsString = searchParams.toString()
        }
        return false
      })
      navigate(`${location.pathname}?${updatedSearchParamsString}`)
    }
  }

  const removeParam = paramName => {
    searchParams.delete(paramName)
    const updatedSearchParamsString = searchParams.toString()
    navigate(
      `${location.pathname}${
        updatedSearchParamsString ? "?" + updatedSearchParamsString : ""
      }`
    )
  }

  const clearParams = () => {
    navigate(`${location.pathname}`)
  }

  const getLinkWithParam = (paramName, paramValue) => {
    searchParams.set(paramName, paramValue)
    return searchParams.toString()
  }

  const getLinkWithParamOnly = (paramName, paramValue) => {
    const newSearchParams = new URLSearchParams()
    newSearchParams.set(paramName, paramValue)
    return newSearchParams.toString()
  }

  const getLinkWithMultipleParams = params => {
    let paramKeys = Object.keys(params)
    if (Array.isArray(paramKeys)) {
      paramKeys?.map(paramKey => {
        if (params[paramKey] != null)
          searchParams.set(paramKey, params[paramKey])
        return false
      })
      return searchParams.toString()
    }
  }

  return {
    getParam,
    getParams,
    setParam,
    setParams,
    addParam,
    addParams,
    getLinkWithParam,
    getLinkWithParamOnly,
    getLinkWithMultipleParams,
    removeParam,
    clearParams,
  }
}

export default useSearchParams
