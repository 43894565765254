import { useDispatch, useSelector } from "react-redux"
import { selectSelectedVisuals, setSelectedVisual } from "../../productsSlice"

const useVisualSelector = product => {
  const dispatch = useDispatch()
  const selectedVisuals = useSelector(selectSelectedVisuals)

  const colorOptions = product?.colorOptions.filter(
    colorOption =>
      colorOption.colorCode != null ||
      (Array.isArray(colorOption.colorCodes) &&
        colorOption.colorCodes[0] != null)
  )

  let selectedVisualIdx = colorOptions
    ? colorOptions.findIndex(colorOption => colorOption.isSelected)
    : 0
  if (selectedVisuals[product?.id] != null)
    selectedVisualIdx = selectedVisuals[product?.id]

  const handleSelectVisual = visualIdx => {
    if (product) {
      dispatch(setSelectedVisual({ productId: product.id, visualIdx }))
    }
  }

  return { handleSelectVisual, selectedVisualIdx, colorOptions }
}

export default useVisualSelector
