import useVisualSelector from "./useVisualSelector"

const useColorOptions = ({ product }) => {
  const {
    handleSelectVisual: handleClick,
    selectedVisualIdx: selectedIndex,
    colorOptions,
  } = useVisualSelector(product)

  return { handleClick, selectedIndex, colorOptions: colorOptions.slice(0, 11) }
}

export default useColorOptions
