import { useState } from "react"
import { useLoginMutation } from "../authApiSlice"
import { setToken } from "../authSlice"
import { useDispatch } from "react-redux"
import md5 from "js-md5"

const useLoginScreen = () => {
  const [loginName, setLoginName] = useState("")
  const [loginPass, setLoginPass] = useState("")
  const [authenticating, setAuthenticating] = useState(false)
  const [authError, setAuthError] = useState(null)
  const [login] = useLoginMutation()
  const dispatch = useDispatch()

  const handleLoginClick = async event => {
    event.preventDefault()
    try {
      setAuthenticating(true)
      setAuthError(null)
      const userData = await login({
        username: loginName,
        password_md5: md5(loginPass),
      }).unwrap()
      setAuthenticating(false)
      if (userData?.success === false) {
        if (userData?.message) {
          setAuthError(userData?.message)
        } else {
          setAuthError("Login rejected by server")
        }
      } else {
        /*
        localStorage.setItem("gasefiToken", userData.token)
        window.location.reload()
        */
        dispatch(setToken(userData.token))
      }
    } catch (err) {
      setAuthenticating(false)
      setAuthError(err)
    }
  }

  return {
    handleLoginClick,
    authenticating,
    loginName,
    setLoginName,
    loginPass,
    setLoginPass,
    authError,
  }
}

export default useLoginScreen
