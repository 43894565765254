import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "../features/api/apiAuthenticatedSlice"
import authReducer from "../features/auth/authSlice"
import productsReducer from "../features/products/productsSlice"
import globalStateReReducer from "./globalState"
import { combineEpics, createEpicMiddleware } from "redux-observable"
import { catchError } from "rxjs/operators"

import {
  axiosWrapper,
  storeReducers,
  heapViewByPositionReducer,
  filterPanelsByPositionReducer,
  historyReducer,
  photoSearchReducer,
  productDetailsReducer,
  getHeapViewByPositionEpic,
  getProductDetailsEpic,
  getFilterPanelsByPositionEpic,
  getPhotoSearchEpic,
  postPhotoSearchEpic,
} from "../components/index"

const tokenItem = "gasefiToken"

const heapViewEpic = (action$, store$, dependencies) =>
  combineEpics(getHeapViewByPositionEpic)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error("heap view epic", error)
      return source
    })
  )

const productDetailsEpic = (action$, store$, dependencies) =>
  combineEpics(getProductDetailsEpic)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error("product details epic", error)
      return source
    })
  )

const filterPanelsEpic = (action$, store$, dependencies) =>
  combineEpics(getFilterPanelsByPositionEpic)(
    action$,
    store$,
    dependencies
  ).pipe(
    catchError((error, source) => {
      console.error("filter panels epic", error)
      return source
    })
  )

const photoSearchEpic = (action$, store$, dependencies) =>
  combineEpics(getPhotoSearchEpic, postPhotoSearchEpic)(
    action$,
    store$,
    dependencies
  ).pipe(
    catchError((error, source) => {
      console.error("photo search epic", error)
      return source
    })
  )

const axiosClient = new axiosWrapper(() => {
  localStorage.removeItem(tokenItem)
})

const authToken =
  typeof localStorage !== "undefined" ? localStorage.getItem(tokenItem) : null
if (authToken) axiosClient.addToken(authToken)

const createStore = () => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      axiosClient,
    },
  })

  const store = configureStore({
    reducer: {
      [storeReducers.HEAP_VIEW_BY_POSITION]: heapViewByPositionReducer,
      [storeReducers.FILTER_PANELS_BY_POSITION]: filterPanelsByPositionReducer,
      [storeReducers.HISTORY]: historyReducer,
      [storeReducers.PHOTO_SEARCH]: photoSearchReducer,
      [storeReducers.PRODUCT_DETAILS]: productDetailsReducer,
      [apiSlice.reducerPath]: apiSlice.reducer,
      auth: authReducer,
      products: productsReducer,
      globalState: globalStateReReducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(epicMiddleware)
        .concat(apiSlice.middleware),
    devTools: process.env.NODE_ENV === "development",
  })

  epicMiddleware.run(heapViewEpic)
  epicMiddleware.run(filterPanelsEpic)
  epicMiddleware.run(photoSearchEpic)
  epicMiddleware.run(productDetailsEpic)

  return store
}

export default createStore
