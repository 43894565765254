import { useRef, useEffect, useContext, useState } from "react"
import { serializePosition, locationToPosition } from "../utils/positionUtils"
import { ResultsObj } from "../utils"
import useVersioning from "./useVersioning"
import { StaticDataContext } from "../templates"
import useSorting from "./useSorting"
import { useDispatch } from "react-redux"
import {
  setProductPage,
  selectProductPage,
  resetSelectedVisuals,
} from "../features/products/productsSlice"
import { useSelector } from "react-redux"

import useNavigator from "./useNavigator"
import { useProducePageQuery } from "../features/products/productsApiSlice"

const defaultPosition = { shape: ["fa.cat.shoe"] }

const useProducts = () => {
  const dispatch = useDispatch()
  const emptyObjRef = useRef()
  const productPageRef = useRef({})
  const [heapView, setHeapView] = useState()
  const { sortingOrder } = useSorting()
  const [pageCounter, setPageCounter] = useState(0)
  const [pageRequest, setPageRequest] = useState(0)
  const { filterPanelVersion, deploymentVersion } = useVersioning()
  const staticData = useContext(StaticDataContext)

  const { location } = useNavigator()
  const position = locationToPosition(location)
  const serializedPosition = serializePosition(position)
  const pageNo = useSelector(selectProductPage)

  const body = {
    coord:
      position && Object.keys(position).length > 0 ? position : defaultPosition,
    order: sortingOrder,
    page_no: pageNo,
    deployment_id: deploymentVersion,
    filter_panels: filterPanelVersion,
  }
  const productPage = useProducePageQuery(body)

  const loadMoreProducts = () => {
    setPageRequest(state => state + 1)
  }

  useEffect(() => {
    productPageRef.current = {}
    if (pageNo !== 0) {
      dispatch(setProductPage(0))
    }
    dispatch(resetSelectedVisuals())
    // eslint-disable-next-line
  }, [serializedPosition])

  useEffect(() => {
    productPageRef.current = {}
    if (pageNo !== 0) {
      dispatch(setProductPage(0))
    }
    dispatch(resetSelectedVisuals())
    // eslint-disable-next-line
  }, [sortingOrder])

  useEffect(() => {
    if (productPage.requestId && productPage.status === "fulfilled") {
      productPageRef.current = {
        ...productPageRef.current,
        ...productPage.data,
        products: productPageRef.current.products
          ? [...productPageRef.current.products, ...productPage.data.products]
          : [...productPage.data.products],
      }

      setHeapView(new ResultsObj(productPageRef.current))
      setPageCounter(
        productPage?.data?.page_no != null ? productPage?.data?.page_no : pageNo
      )
      setPageRequest(
        productPage?.data?.page_no != null ? productPage?.data?.page_no : pageNo
      )
    }
    if (productPage.requestId && productPage.status === "rejected") {
      setPageRequest(pageCounter)
      dispatch(setProductPage(pageCounter))
    }
    // eslint-disable-next-line
  }, [productPage.requestId, productPage.status])

  useEffect(() => {
    if (pageRequest > pageCounter) {
      if (
        pageRequest === pageCounter + 1 &&
        pageRequest <= productPage?.data?.no_of_pages
      ) {
        if (heapView != null) heapView.loadingMore = true
        dispatch(setProductPage(pageRequest))
      }
    }
    // eslint-disable-next-line
  }, [pageRequest])

  if (emptyObjRef.current == null) {
    if (heapView) {
      emptyObjRef.current = heapView
    } else {
      emptyObjRef.current = new ResultsObj(staticData || {})
    }
  }

  return {
    heapView: heapView || emptyObjRef.current,
    loadingHeapView: productPage.isLoading,
    loadMoreProducts,
    totalCount: productPage?.data?.total,
    loading: productPage.isLoading,
    loadingMore: productPage.isFetching && pageNo > 0,
  }
}

export default useProducts
