export const defaultLocale = "en_GB.UTF-8"

export const labels = {
  "en_GB.UTF-8": {
    EMPTY: "",
    FROM: "from",
    LOADING: "Loading",
    BTN_CLEAR: "Clear",
    BTN_CLOSE: "Close",
    BTN_REMOVE: "Remove",
    BTN_SHOW_RESULTS: "Show Results",
    ACTIVE_FILTERS: "Active Filters:",
    CATEGORY_SUGGESTIONS: "Select a More Specific Category:",
    PRICE_COUNT_LOWER_PRICE: "products with lower price",
    PRICE_COUNT_HIGHER_PRICE: "products with higher price",
    COLOR_OPTIONS: "Select a Color:",
    GROUP: "Group",
    GROUP_BY: "Group by",
    GROUP_NONE: "Group -",
    GROUP_BY_SHAPE: "Group by Shape",
    MORE_OPTIONS: "More Options",
    MULTICHOICE_ON: "Multichoice is On",
    MULTICHOICE_OFF: "Multichoice is Off",
    PRODUCT: "Product",
    PRODUCTS: "Products",
    PRODUCTS_WITH_COUNT: "%VALUE% products",
    PRODUCT_FOUND: "Product Found",
    PRODUCTS_FOUND: "Products Found",
    SORT: "Sort",
    SORT_BY: "Sort by",
    SORT_NONE: "Sort -",
    SORT_BY_POPULARITY: "Sort by Popularity",
    SORT_BY_PRICE_ASC: "Sort by Price ↑",
    SORT_BY_PRICE_DESC: "Sort by Price ↓",
    SEARCH_QUERY_PLACEHOLDER: "By Keyword",
    SEARCH_QUERY_NO_SUGGESTIONS: "No Suggestions Found",
    NO_AVAILABLE_FILTERS: "No Available Filters",
    MANAGE_CHOOSE_TO_ADD: "Choose a Filter to Add:",
    MANAGE_EDIT: "Edit Filters",
    UPLOAD_START_NEW_SEARCH: "Start New Search",
    UPLOAD_SEARCH_BY_PHOTO: "Search by Photo",
    UPLOAD_NEW_SEARCH: "New Search",
    UPLOAD_RECENT_UPLOADS: "Recent Uploads:",
    UPLOAD_SERVER_IS_PROCESSING: "Server is processing your photo.",
    UPLOAD_THANK_YOU: "Thank you for the patience.",
    UPLOAD_JUST_NOW: "Just now",
    UPLOAD_SECONDS_AGO: "%VALUE% seconds ago",
    UPLOAD_MINUTES_AGO: "%VALUE% minutes ago",
    UPLOAD_HOURS_AGO: "%VALUE% hours ago",
    UPLOAD_DAYS_AGO: "%VALUE% days ago",
    UPLOAD_WEEKS_AGO: "%VALUE% weeks ago",
    UPLOAD_MONTHS_AGO: "%VALUE% months ago",
    UPLOAD_YEARS_AGO: "%VALUE% years ago",
    UPLOAD_DETECTED_PROPERTIES: "Detected Properties:",
    UPLOAD_APPLY_FILTERS: "Apply Filters",
    UPLOAD_PASTE: "Paste (Ctrl + v / ⌘ + v) an image or URL",
    UPLOAD_DRAG_AND_DROP: "Drag and drop an image",
    UPLOAD_OR: "OR",
    VENDORS: "Vendors:",
  },
}
