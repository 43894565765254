import React, { forwardRef } from "react"
import classNames from "classnames"
import { ariaRoles } from "../../utils/constants"
import KeywordList from "../keywordSearch/KeywordList"

import * as styles from "./dropdown.module.css"

export const Dropdown = forwardRef(({ landing = false }, ref) => {
  return (
    <div
      ref={ref}
      role={ariaRoles.DIALOG}
      className={classNames(styles.dropdown, { [styles.landing]: landing })}
    >
      <KeywordList />
    </div>
  )
})
