import { apiSlice } from "../api/apiAuthenticatedSlice"

export const keywordSearchSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    search: builder.query({
      query: arg => ({
        url: "/filipa/tools/keyword_search",
        method: "POST",
        body: { ...arg },
      }),
      providesTags: (result, error, arg) => [
        { type: "Keywords", id: arg && arg.text ? arg.text : "" },
      ],
    }),
  }),
})

export const { useSearchQuery } = keywordSearchSlice
