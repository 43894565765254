import { useRef } from "react"
import usePanel from "../../../hooks/usePanel"
import useVersioning from "../../../hooks/useVersioning"
import useLocale from "../../../hooks/useLocale"
import { useEstimateVisibleQuery } from "../filtersSlice"
import { locationToPosition } from "../../../utils/helpers"
import { panelKeys, syntheticPanels } from "../../../utils/constants"
import { useContext } from "react"
import { StaticDataContext } from "../../../templates"

const DEFAULT_PANEL = panelKeys.SHAPE_FILTER
const defaultPosition = { shape: ["fa.cat.shoe"] }

const useEstimateForLocation = ({ location, withCounts, caller, skip }) => {
  const coord = location ? locationToPosition(location) : defaultPosition
  const { openPanel } = usePanel()
  const staticData = useContext(StaticDataContext)

  const getPanelsForEstimation = openPanel => {
    if (openPanel == null || openPanel === panelKeys.ADD_FILTER)
      return [DEFAULT_PANEL]
    if (openPanel === panelKeys.PRICE_FILTER)
      return syntheticPanels[panelKeys.PRICE_FILTER]
    if (openPanel === panelKeys.COLOR_FILTER)
      return [
        ...syntheticPanels[panelKeys.COLOR_FILTER],
        panelKeys.COLOR_FILTER,
      ]
    return [openPanel]
  }

  const {
    deploymentVersion: deployment_id,
    filterPanelVersion: filter_panels,
    captionsVersion: captions,
  } = useVersioning()
  const locale = useLocale()
  let body = {
    captions,
    coord,
    deployment_id,
    estimate_panels_for: getPanelsForEstimation(caller ? caller : openPanel),
    estimate_count_for: withCounts
      ? getPanelsForEstimation(caller ? caller : openPanel)
      : [],
    filter_panels,
    locale,
  }
  const estimateVisible = useEstimateVisibleQuery(body, {
    skip: Object.keys(body?.coord).length < 1,
  })

  let estimateWithStatic = estimateVisible.data != null ? estimateVisible : {}
  if (estimateVisible.data == null && staticData && staticData.filterPanels) {
    estimateWithStatic = {
      ...estimateVisible,
      data: staticData && staticData.filterPanels,
    }
  }

  return {
    estimateVisible: estimateWithStatic,
  }
}

export default useEstimateForLocation
