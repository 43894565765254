import React from "react"
import { Helmet } from "react-helmet"
import { AppRedux as App } from "../components/App"
import { Landing } from "../components/Landing"
import { graphql } from "gatsby"
import { appName, favicon } from "../config/customizations"
import { createContext } from "react"

/*
export const query = graphql`
  query($pathname: String!) {
    productsJson(pathname: { eq: $pathname }) {
      heaps {
        id
        caption
        images
        icon
        mutation {
          category
        }
        count
      }
      products {
        id
        brand
        image
        name
        price
      }
    }
  }
`
*/
export const query = graphql`
  query ($relativeDirectory: String!) {
    metaData: allFile(
      filter: {
        relativeDirectory: { eq: $relativeDirectory }
        childrenMetaJson: { elemMatch: { id: { ne: "" } } }
      }
    ) {
      nodes {
        childMetaJson {
          title
          description
        }
      }
    }
    productData: allFile(
      filter: {
        relativeDirectory: { eq: $relativeDirectory }
        childrenProductsJson: { elemMatch: { id: { ne: "" } } }
      }
    ) {
      nodes {
        childProductsJson {
          pageCount
          pageIndex
          products {
            id
            image
            name
          }
        }
      }
    }
    filterData: allFile(
      filter: {
        relativeDirectory: { eq: $relativeDirectory }
        childrenFilterPanelsJson: { elemMatch: { id: { ne: "" } } }
      }
    ) {
      nodes {
        childFilterPanelsJson {
          shape {
            id
            caption
            name
            button_visualization {
              id
              type
              url
            }
            category_visualization {
              id
              type
              url
            }
            current_choice {
              visualization {
                id
                type
                url
              }
              caption
            }
          }
          _coord {
            shape {
              id
              name
            }
          }
        }
      }
    }
  }
`

/*
          heaps {
            id
            caption
            images
            icon
            mutation {
              category
            }
            count
          }
*/

const defaultTitle = appName
const defaultDescription = "World's largest product catalog"

export const StaticDataContext = createContext(null)

export default function page({
  data,
  pageContext: { pathname, relativeDirectory, utcTime, homeDir, testing },
}) {
  const staticData = {
    heaps:
      data &&
      data.productData &&
      data.productData.nodes[0] &&
      data.productData.nodes[0].childProductsJson
        ? data.productData.nodes[0].childProductsJson.heaps
        : [],
    products:
      data &&
      data.productData &&
      data.productData.nodes[0] &&
      data.productData.nodes[0].childProductsJson
        ? data.productData.nodes[0].childProductsJson.products
        : [],
    filterPanels:
      data &&
      data.filterData &&
      data.filterData.nodes[0] &&
      data.filterData.nodes[0].childFilterPanelsJson
        ? data.filterData.nodes[0].childFilterPanelsJson
        : {},
    title:
      data &&
      data.metaData &&
      data.metaData.nodes[0] &&
      data.metaData.nodes[0].childMetaJson
        ? `${
            data.metaData.nodes[0].childMetaJson.title
              ? data.metaData.nodes[0].childMetaJson.title + " | "
              : ""
          }${appName} `
        : defaultTitle,
    description:
      data &&
      data.metaData &&
      data.metaData.nodes[0] &&
      data.metaData.nodes[0].childMetaJson
        ? data.metaData.nodes[0].childMetaJson.description
        : defaultDescription,
  }

  const renderHelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{staticData.title}</title>
        <meta name="date.modified" content={utcTime} />
        <meta name="description" content={staticData.description} />
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <html lang="en" />
        <link rel="icon" type="image/x-icon" href={favicon} />
      </Helmet>
    )
  }

  var adjustedPathname =
    homeDir !== "/" && pathname === "/" ? homeDir + pathname : pathname
  var adjustedHomeDir = homeDir !== "/" ? homeDir + "/" : homeDir

  if (typeof homeDir === "undefined") return null

  return (
    <>
      {renderHelmet()}
      {adjustedPathname === adjustedHomeDir ? (
        <Landing />
      ) : (
        <StaticDataContext.Provider value={staticData}>
          <App
            staticPathname={pathname}
            staticData={staticData}
            homeDir={adjustedHomeDir}
            testing={typeof testing !== "undefined"}
          />
        </StaticDataContext.Provider>
      )}
    </>
  )
}
