import React, { useRef, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import useHistoryState from "../../hooks/useHistoryState"
import useLocale from "../../hooks/useLocale"
import ReactSlider from "react-slider" // https://zillow.github.io/react-slider/
import useNavigator from "../../hooks/useNavigator.js"
import useFilters from "../../features/filters/hooks/useFilters.js"

import {
  panelKeys as constPanelKeys,
  safeLinkActions,
  SafeLinkObj,
  historyWrapper,
  saveHistory,
  INFINITY,
  formatProductCount,
  labels,
} from "../index"

import {
  priceValues,
  priceSlider,
  priceSliderTrack,
  priceSliderThumb,
  priceSliderThumbActive,
} from "./priceSelector.module.css"

export const PriceSelector = () => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const { position } = useNavigator()
  const { filterPanel: filterPanelObj } = useFilters({
    caller: constPanelKeys.PRICE_FILTER,
  })
  const historyState = useHistoryState()
  const panelKeys = [
    constPanelKeys.PRICE_FILTER_GTE,
    constPanelKeys.PRICE_FILTER_LTE,
  ]

  const gteLengthRef = useRef(0)

  var gteIndex = 0
  var lteIndex = 0
  var minValue = "$0"
  var maxValue = "$0+"
  var gteCount = 0
  var lteCount = 0
  var currentCount = 0
  var lteMaxIndex = 0
  /*
  if (
    filterPanelObj.panelData &&
    filterPanelObj.panelData[panelKeys[0]] &&
    filterPanelObj.panelData[panelKeys[1]] &&
    filterPanelObj.panelData[panelKeys[0]].filtributes &&
    filterPanelObj.panelData[panelKeys[1]].filtributes &&
    filterPanelObj.panelData[panelKeys[0]].filtributes.length > 0 &&
    filterPanelObj.panelData[panelKeys[1]].filtributes.length > 0
  ) {
    lteMaxIndex = filterPanelObj.panelData[panelKeys[1]].filtributes.length - 1
    gteIndex = filterPanelObj.panelData[panelKeys[0]].filtributes.findIndex(
      item => item.selected
    )
    lteIndex = filterPanelObj.panelData[panelKeys[1]].filtributes.findIndex(
      item => item.selected
    )
    gteIndex = Math.max(0, gteIndex)
    lteIndex = lteIndex === -1 ? lteMaxIndex : lteIndex
  }
  */

  const [sliderValues, setSliderValues] = useState([0, 0])
  const sliderValuesRef = useRef()

  useEffect(() => {
    if (
      filterPanelObj.panelData[constPanelKeys.PRICE_FILTER_LTE] &&
      filterPanelObj.panelData[constPanelKeys.PRICE_FILTER_LTE].filtributes &&
      gteLengthRef.current !==
        filterPanelObj.panelData[constPanelKeys.PRICE_FILTER_LTE].filtributes
          .length
    ) {
      gteLengthRef.current =
        filterPanelObj.panelData[
          constPanelKeys.PRICE_FILTER_LTE
        ].filtributes.length

      lteMaxIndex =
        filterPanelObj.panelData[panelKeys[1]].filtributes.length - 1
      gteIndex = filterPanelObj.panelData[panelKeys[0]].filtributes.findIndex(
        item => item.selected
      )
      lteIndex = filterPanelObj.panelData[panelKeys[1]].filtributes.findIndex(
        item => item.selected
      )
      gteIndex = Math.max(0, gteIndex)
      lteIndex = lteIndex === -1 ? lteMaxIndex : lteIndex
      setSliderValues([gteIndex, lteIndex + 1])
    }
  }, [filterPanelObj.panelData[constPanelKeys.PRICE_FILTER_LTE]])

  if (
    filterPanelObj.panelData &&
    filterPanelObj.panelData[panelKeys[0]] &&
    filterPanelObj.panelData[panelKeys[1]] &&
    filterPanelObj.panelData[panelKeys[0]].filtributes &&
    filterPanelObj.panelData[panelKeys[1]].filtributes &&
    filterPanelObj.panelData[panelKeys[0]].filtributes.length > 0 &&
    filterPanelObj.panelData[panelKeys[1]].filtributes.length > 0
  ) {
    lteMaxIndex = filterPanelObj.panelData[panelKeys[1]].filtributes.length - 1
    gteIndex = Math.min(
      sliderValues[0],
      filterPanelObj.panelData[panelKeys[0]].filtributes.length
    )
    lteIndex = Math.max(sliderValues[1] - 1, 0)

    minValue =
      "$" +
      filterPanelObj.panelData[panelKeys[0]].filtributes[gteIndex].numerical
    maxValue =
      "$" +
      filterPanelObj.panelData[panelKeys[1]].filtributes[lteIndex].numerical

    if (
      filterPanelObj.panelData[panelKeys[1]].filtributes[lteIndex].numerical ===
      INFINITY
    ) {
      maxValue =
        "$" +
        filterPanelObj.panelData[panelKeys[1]].filtributes[lteIndex - 1]
          .numerical +
        "+"
    }

    gteCount =
      filterPanelObj.panelData[panelKeys[0]].filtributes[0].count -
      filterPanelObj.panelData[panelKeys[0]].filtributes[gteIndex].count
    lteCount =
      filterPanelObj.panelData[panelKeys[1]].filtributes[lteMaxIndex].count -
      filterPanelObj.panelData[panelKeys[1]].filtributes[lteIndex].count

    currentCount =
      filterPanelObj.panelData[panelKeys[0]].filtributes[0].count - gteCount
    if (
      sliderValuesRef.current &&
      sliderValuesRef.current[1] !== sliderValues[1]
    ) {
      currentCount =
        filterPanelObj.panelData[panelKeys[1]].filtributes[lteMaxIndex].count -
        lteCount
    }
  }

  const handleBeforeChange = () => {
    sliderValuesRef.current = sliderValues
  }

  const handleAfterChange = () => {
    if (sliderValuesRef.current[0] !== sliderValues[0]) {
      const safeLinkObj = new SafeLinkObj(
        safeLinkActions.CHOOSE_VALUE,
        { panelId: panelKeys[0] },
        historyState,
        historyWrapper.location
      )
      const newState = safeLinkObj.newState
      const newUrl =
        filterPanelObj.panelData[panelKeys[0]].filtributes[
          sliderValues[0]
        ].getLinkUrl(position)
      historyWrapper.pushState(newState, "", newUrl)
      dispatch(saveHistory(historyWrapper.location, historyWrapper.state))
    }
    if (sliderValuesRef.current[1] !== sliderValues[1]) {
      const safeLinkObj = new SafeLinkObj(
        safeLinkActions.CHOOSE_VALUE,
        { panelId: panelKeys[1] },
        historyState,
        historyWrapper.location
      )
      const newState = safeLinkObj.newState
      const newUrl =
        filterPanelObj.panelData[panelKeys[1]].filtributes[
          sliderValues[1] - 1
        ].getLinkUrl(position)
      historyWrapper.pushState(newState, "", newUrl)
      dispatch(saveHistory(historyWrapper.location, historyWrapper.state))
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className={priceValues}>{minValue + " - " + maxValue}</div>
        <div
          style={{ color: "var(--clr-grey)", fontSize: "var(--font-size-l)" }}
        >
          {labels[locale].PRODUCTS_WITH_COUNT.replace(
            "%VALUE%",
            formatProductCount(currentCount)
          )}
        </div>
      </div>
      <ReactSlider
        className={priceSlider}
        trackClassName={priceSliderTrack}
        thumbClassName={priceSliderThumb}
        thumbActiveClassName={priceSliderThumbActive}
        min={0}
        minDistance={1}
        max={lteMaxIndex + 1}
        value={sliderValues}
        onChange={setSliderValues}
        onBeforeChange={handleBeforeChange}
        onAfterChange={handleAfterChange}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div style={{ color: "var(--clr-grey)" }}>
          {gteCount > 0 && (
            <>
              <div style={{ fontSize: "var(--font-size-s)" }}>
                {formatProductCount(gteCount)}
              </div>
              <div style={{ fontSize: "var(--font-size-xs)" }}>
                {labels[locale].PRICE_COUNT_LOWER_PRICE}
              </div>
            </>
          )}
        </div>
        <div style={{ textAlign: "right", color: "var(--clr-grey)" }}>
          {lteCount > 0 && (
            <>
              <div style={{ fontSize: "var(--font-size-s)" }}>
                {formatProductCount(lteCount)}
              </div>
              <div style={{ fontSize: "var(--font-size-xs)" }}>
                {labels[locale].PRICE_COUNT_HIGHER_PRICE}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
