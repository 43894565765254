// extracted by mini-css-extract-plugin
export var closeIcon = "textSearch-module--closeIcon--Nua-y";
export var dropdown = "textSearch-module--dropdown--q+Tsh";
export var dropdownInOverlay = "textSearch-module--dropdownInOverlay--qfoY4";
export var inLanding = "textSearch-module--inLanding--GOxHG";
export var landing = "textSearch-module--landing--JOHmK";
export var noSuggestions = "textSearch-module--noSuggestions--B-r-r";
export var searchIcon = "textSearch-module--searchIcon--gHJFP";
export var searchIconInOverlay = "textSearch-module--searchIconInOverlay---xlhR";
export var searchInput = "textSearch-module--searchInput--8GySU";
export var searchInputInOverlay = "textSearch-module--searchInputInOverlay--Uz9QX";
export var searchInputWrapperInOverlay = "textSearch-module--searchInputWrapperInOverlay--4xjye";
export var searchTextHighlighted = "textSearch-module--searchTextHighlighted--tBE6r";
export var suggestionImage = "textSearch-module--suggestionImage--PKOij";
export var suggestionLink = "textSearch-module--suggestionLink--Q1GKw";
export var textSearchOverlay = "textSearch-module--textSearchOverlay--gnZyh";
export var textSearchWrapper = "textSearch-module--textSearchWrapper--RYjzp";