import React from "react"
import PropTypes from "prop-types"
import { SafeLink } from "../../components/index"
import { safeLinkActions } from "../../utils/constants"
import { getPathnameString } from "../../utils/helpers"
import { Icon } from "../../components/index"
import { icons, iconsColors } from "../../components/icon.utils"

import * as styles from "../../components/buttons/safeButton.module.css"
import * as safeLinkStyles from "../../components/safeLink.module.css"

export const PhotoUploadButton = ({ location, landing = false }) => {
  return (
    <SafeLink
      to={getPathnameString(location)}
      action={safeLinkActions.SHOW_UPLOADS}
      className={safeLinkStyles.noUnderline}
    >
      <Icon icon={icons.camera} color={iconsColors.BLACK} size={32} />
      {landing && <div className={styles.uploadButtonText}>By photo</div>}
    </SafeLink>
  )
}

PhotoUploadButton.propTypes = {
  location: PropTypes.object.isRequired,
}
