import { useState } from "react"
import useFilters from "../../hooks/useFilters"
import { panelKeys } from "../../../../utils/constants"

const useBrandSelector = () => {
  const { filterPanel } = useFilters({ caller: panelKeys.BRAND_FILTER })
  const [textFilterValue, setTextFilterValue] = useState("")

  const filteredFiltributes = filterPanel.filtributes.filter(filtribute => {
    return filtribute.text.toLowerCase().includes(textFilterValue.toLowerCase())
  })

  let activeFiltributes = []
  let availableFiltributes = []
  filteredFiltributes.map(filtribute => {
    if (filtribute.selected) {
      activeFiltributes.push(filtribute)
    } else if (filtribute.count > 0) {
      availableFiltributes.push(filtribute)
    }
    return false
  })

  return {
    textFilterValue,
    setTextFilterValue,
    activeFiltributes,
    availableFiltributes,
  }
}

export default useBrandSelector
