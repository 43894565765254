import React from "react"
import useKeywordList from "./hooks/useKeywordList"
import useLocale from "../../hooks/useLocale"
import { Loader } from "../../components/basicComponents/loader"
import { labels } from "../../utils/labelConstants"
import { TextSearchButton } from "../../components"
import Visualization from "./Visualization"
import getRoundedNumber from "../../utils/getRoundedNumber"
import * as styles from "./keywordList.module.css"

const KeywordList = () => {
  const locale = useLocale()
  const { keywords, keywordsLoading } = useKeywordList()
  return (
    <>
      {keywordsLoading ? (
        <Loader small />
      ) : keywords && keywords.length > 0 ? (
        keywords.map(item => {
          return (
            <div key={item.id} className={styles.suggestionLink}>
              <TextSearchButton pathname={item.link}>
                <p className={styles.keywordCaption}>{item.caption}</p>
                <div className={styles.visualizationContainer}>
                  {Array.isArray(item.visualization)
                    ? item.visualization.map((visualization, idx) => (
                        <Visualization
                          key={`${visualization.id}_${visualization.idx}`}
                          visualization={{
                            ...visualization,
                            idx,
                            caption: item.caption,
                          }}
                        />
                      ))
                    : null}
                </div>
                <div className={styles.countContainer}>
                  {item.count != null ? (
                    <p className={styles.count}>
                      {getRoundedNumber(item.count)}
                    </p>
                  ) : null}
                </div>
              </TextSearchButton>
            </div>
          )
        })
      ) : (
        <div className={styles.noSuggestions}>
          {labels[locale].SEARCH_QUERY_NO_SUGGESTIONS}
        </div>
      )}
    </>
  )
}

export default KeywordList
