import { useState, useEffect, useRef } from "react"
import useNavigator from "../../../hooks/useNavigator"
import usePanel from "../../../hooks/usePanel"
import { FilterObj } from "../../../components"
import useEstimateForLocation from "./useEstimateForLocation"
import { panelKeys } from "../../../utils/constants"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { setPanelData } from "../../../store/globalState"

const useFilters = ({ caller, counts, skip }) => {
  const [filterPanels, setFilterPanels] = useState()
  const dispatch = useDispatch()
  const emptyObjRef = useRef()
  const callerRef = useRef()
  const lastLocation = useSelector(
    state =>
      state.globalState.filterPanels[
        caller || openPanel || panelKeys.SHAPE_FILTER
      ]
  )
  const { location } = useNavigator()
  const { openPanel } = usePanel()
  const { estimateVisible: estimateFromCache } = useEstimateForLocation({
    location: lastLocation || location,
    caller: caller || panelKeys.SHAPE_FILTER,
  })
  if (emptyObjRef.current == null) {
    emptyObjRef.current = new FilterObj(estimateFromCache.data)
  }

  const { estimateVisible } = useEstimateForLocation({
    location,
    caller: caller || panelKeys.SHAPE_FILTER,
    skip: skip,
  })
  const { estimateVisible: estimateVisibleWithCounts } = useEstimateForLocation(
    {
      location,
      withCounts: true,
      caller: caller || panelKeys.SHAPE_FILTER,
      skip: counts === false || skip,
    }
  )

  useEffect(() => {
    if (estimateVisible.requestId && estimateVisible.status === "fulfilled") {
      setFilterPanels(new FilterObj(estimateVisible.data))
      dispatch(
        setPanelData({
          panel: caller || panelKeys.SHAPE_FILTER,
          data: location,
        })
      )
    }
  }, [estimateVisible.requestId, estimateVisible.status])

  useEffect(() => {
    if (
      estimateVisibleWithCounts.requestId &&
      estimateVisibleWithCounts.status === "fulfilled"
    ) {
      setFilterPanels(new FilterObj(estimateVisibleWithCounts.data))
      dispatch(
        setPanelData({
          panel: caller || panelKeys.SHAPE_FILTER,
          data: location,
        })
      )
    }
  }, [estimateVisibleWithCounts.requestId, estimateVisibleWithCounts.status])

  useEffect(() => {
    if (estimateVisibleWithCounts.status === "fulfilled") {
      setFilterPanels(new FilterObj(estimateVisibleWithCounts.data))
    } else if (estimateVisible.status === "fulfilled") {
      setFilterPanels(new FilterObj(estimateVisible.data))
    } else {
      setFilterPanels(new FilterObj(estimateFromCache.data, true))
    }
  }, [location.href])

  useEffect(() => {
    if (caller !== null && caller !== callerRef.current) {
      if (estimateVisibleWithCounts.status === "fulfilled") {
        setFilterPanels(new FilterObj(estimateVisibleWithCounts.data))
      } else if (estimateVisible.status === "fulfilled") {
        setFilterPanels(new FilterObj(estimateVisible.data))
      } else {
        setFilterPanels(new FilterObj(estimateFromCache.data, true))
      }
      callerRef.current = caller
    }
  }, [caller])

  const panelsToReturn = filterPanels || emptyObjRef.current
  const panelToReturn = panelsToReturn.getPanel(caller ? caller : openPanel)

  return {
    filterPanels: panelsToReturn,
    filterPanel: panelToReturn,
    loadingFilterPanels: estimateVisible.isLoading,
  }
}

export default useFilters
