import { apiSlice } from "../api/apiAuthenticatedSlice"

const createId = arg => {
  let id
  if (arg == null) return "all"
  if (arg.coord != null) id = JSON.stringify(arg.coord)
  if (arg.deployment_id != null) id = `${id}_${arg.deployment_id}`
  return id
}

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    producePage: builder.query({
      query: arg => ({
        url: "/filipa/tools/produce_page",
        method: "POST",
        body: { ...arg },
      }),
      providesTags: (result, error, arg) => [
        { type: "Products", id: createId(arg) },
      ],
    }),
    getProduct: builder.query({
      query: arg => ({
        url: `/filipa/deployments/${arg.deploymentId}/products/${arg?.id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Product", id: `${arg.deploymentId}-${arg?.id}` }],
    }),
  }),
})

export const { useProducePageQuery, useGetProductQuery } = productsApiSlice
