import React from "react"
import classNames from "classnames"
import useColorOptions from "./hooks/useColorOptions"
import ColorIndicator from "../../indicators/ColorIndicator"
import * as styles from "./colorOptions.module.css"

const ColorOptions = ({ product }) => {
  const { handleClick, selectedIndex, colorOptions } = useColorOptions({
    product,
  })

  const colorOptionComponents =
    colorOptions.length > 0
      ? colorOptions.map(colorOption => {
          return (
            <div
              key={colorOption.id}
              className={styles.colorOption}
              onClick={() => {
                handleClick(colorOption.index)
              }}
            >
              <div
                className={classNames(styles.colorSelectionIndicator, {
                  [styles.selected]: selectedIndex === colorOption.index,
                })}
              >
                <ColorIndicator
                  selected={selectedIndex === colorOption.index}
                  colorCodes={colorOption.colorCodes}
                  colorImg={colorOption.imageUrl}
                />
              </div>
            </div>
          )
        })
      : null

  return (
    <div className={styles.colorOptionsContainer}>{colorOptionComponents}</div>
  )
}

export default ColorOptions
