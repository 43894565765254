import React from "react"
import classNames from "classnames"
import { MulticolorIndicator } from "./multicolorIndicator"
import _ColorIndicator from "../../../features/indicators/ColorIndicator"
import * as styles from "./indicators.module.css"

export const indicatorPlacementOptions = {
  TOOLBOX: "toolbox",
  PRODUCT_CARD: "productCard",
  EXPANDED_CARD: "expandedCard",
  FILTER_PANEL: "filterPanel",
}

export const ColorIndicator = ({
  isSelected,
  selection,
  indicatorPlacement = indicatorPlacementOptions.TOOLBOX,
}) => {
  if (
    selection &&
    selection.button &&
    Object.keys(selection.button).length > 0
  ) {
    return <MulticolorIndicator selection={selection} />
  }

  return (
    <div
      className={classNames(
        styles.colorSelectionIndicator,
        styles[indicatorPlacement],
        {
          [styles.selected]:
            isSelected != null ? isSelected : selection.isSelected,
        }
      )}
    >
      <_ColorIndicator
        selected={isSelected}
        colorCodes={selection.colorCodes}
        colorImg={selection.imageUrl}
      />
    </div>
  )
}
