import React from "react"
import useLocale from "../../hooks/useLocale"
import VendorBox from "./VendorBox"
import PropTypes from "prop-types"
import { Loader } from "../../components/index"
import useProductCardExpanded from "./hooks/useProductCardExpanded"

import {
  ColorIndicator,
  labels,
  indicatorPlacementOptions,
} from "../../components/index"

import * as styles from "./productCardExpanded.module.css"
import * as labelStyles from "../../styles/labelStyles.module.css"
import { cColorOptionContainer } from "./productCardExpanded.module.css"

export const ProductCardExpanded = ({ product, detailsLoading }) => {
  const { handleClick, selectedOptionIndex, selectedOption, colorOptions } =
    useProductCardExpanded({
      product,
    })

  const showProductId =
    window && window.gsfTesting && window.gsfTesting.showProductId
  const showVisualId =
    window && window.gsfTesting && window.gsfTesting.showVisualId
  const locale = useLocale()

  if (!product) return null
  const visualId = selectedOption ? selectedOption.id : null

  const colorOptionsComponent = colorOptions.map(colorOption => {
    return (
      <div
        key={colorOption.id}
        onClick={() => {
          handleClick(colorOption.index)
        }}
        style={{ cursor: "pointer" }}
      >
        <ColorIndicator
          isSelected={colorOption.index === selectedOptionIndex}
          selection={colorOption}
          indicatorPlacement={indicatorPlacementOptions.EXPANDED_CARD}
        />
      </div>
    )
  })

  return (
    <div
      data-testid="product-card-expanded"
      className={styles.productCardExpanded}
    >
      <div className={styles.imageContainer}>
        <img
          src={selectedOption.productImage}
          key={selectedOption.productImage}
          alt={product.name}
          loading="lazy"
        />
        {showProductId || showVisualId ? (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              backgroundColor: "white",
              color: "#aaa",
              padding: "4px",
            }}
          >
            {showProductId ? <p>{"product ID: " + product.id}</p> : null}
            {showVisualId ? <p>{"visual ID: " + visualId}</p> : null}
          </div>
        ) : null}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.colorContainer}>
          <div className={labelStyles.productCaption}>
            {labels[locale].COLOR_OPTIONS}
          </div>
          <div className={styles.colorOptions}>
            <div className={cColorOptionContainer}>{colorOptionsComponent}</div>
          </div>
        </div>
        <div className={styles.colorContainer}>
          <div className={labelStyles.productCaption}>
            {labels[locale].VENDORS}
          </div>
          {detailsLoading ? (
            <Loader small />
          ) : (
            <>
              {selectedOption &&
              Array.isArray(selectedOption.storeIds) &&
              selectedOption.storeIds.length > 0 ? (
                <>
                  {selectedOption.storeIds.map(storeId => (
                    <VendorBox
                      key={storeId}
                      id={storeId}
                      store={selectedOption.storeEntities[storeId]}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ProductCardExpanded.propTypes = {
  product: PropTypes.object,
}

/*
          {detailsLoading ? (
            <Loader />
          ) : (
            <>
              {product &&
              product.selectedOption &&
              product.selectedOption.storeIds &&
              product.selectedOption.storeIds.length > 0 ? (
                <>
                  {product.selectedOption.storeIds.map(storeId => (
                    <VendorBox
                      key={storeId}
                      id={storeId}
                      store={product.selectedOption.storeEntities[storeId]}
                    />
                  ))}
                </>
              ) : null}
            </>
          )}
*/
