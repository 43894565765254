import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ResultsHeader } from "./resultsHeader"
import ProductCards from "./ProductCards"

import useResults from "./hooks/useResults"
import * as styles from "./results.module.css"

export const Results = forwardRef(({ hideSearchResults }, ref) => {
  const { isOpenFilterPanel, isOpenDialog, loading, productCount } =
    useResults()

  return (
    <div
      ref={ref}
      className={classNames(styles.searchResultsWrapper, {
        [styles.hideSearchResults]: hideSearchResults,
        [styles.filterPanelOpen]: isOpenFilterPanel || isOpenDialog,
      })}
    >
      <div
        className={classNames(styles.searchResults, {
          [styles.searchResultsWithOpenFilterPanel]: isOpenFilterPanel,
        })}
      >
        <ResultsHeader loading={loading} productCount={productCount} />
        <ProductCards />
      </div>
    </div>
  )
})

Results.propTypes = {
  hideSearchResults: PropTypes.bool,
  setHideSearchResults: PropTypes.func,
}
