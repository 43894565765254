import { apiSlice } from "../api/apiAuthenticatedSlice"

const createId = arg => {
  let id
  if (arg == null) return "all"
  if (arg.coord != null) id = JSON.stringify(arg.coord)
  if (arg.deployment_id != null) id = `${id}_${arg.deployment_id}`
  return id
}

export const filtersSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    estimateVisible: builder.query({
      query: arg => ({
        url: "/filipa/tools/filter_panels/estimate_visible",
        method: "POST",
        body: { ...arg },
      }),
      providesTags: (result, error, arg) => [
        { type: "FilterPanels", id: createId(arg) },
      ],
    }),
  }),
})

export const { useEstimateVisibleQuery } = filtersSlice
