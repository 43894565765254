import React from "react"
import classNames from "classnames"

import * as styles from "./filtributes/filtribute.module.css"

export const Collection = ({
  collection,
  openCollections,
  handleCollectionToggle,
  children,
}) => {
  const hasSelectedItems = collection => {
    let result = false
    collection.items.map(selection => {
      if (selection.selected) result = true
      return false
    })
    return result
  }

  return (
    <div key={collection.id} style={{ width: "100%" }}>
      <div
        onClick={() => {
          handleCollectionToggle(collection.id)
        }}
        style={{
          position: "relative",
          borderBottom: "1px solid var(--clr-grey-l2)",
          cursor: "pointer",
        }}
      >
        <div
          className={classNames(styles.addPanelFiltribute, styles.collection)}
          style={{
            position: "relative",
            height: "54px",
          }}
        >
          <div style={{ textTransform: "capitalize" }}>
            {collection.caption}
          </div>
          {hasSelectedItems(collection) ? (
            <div
              style={{
                marginLeft: "10px",
                width: "10px",
                height: "10px",
                borderRadius: "5px",
                backgroundColor: "var(--clr-turquoise)",
              }}
            />
          ) : (
            <div style={{ display: "none" }} />
          )}
        </div>
      </div>
      {openCollections.indexOf(collection.id) >= 0 ? (
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          {children}
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
    </div>
  )
}
