import React from "react"
import classNames from "classnames"
import { Icon, icons, iconsColors } from "../../index"
import * as styles from "./filtribute.module.css"

export const CategoryFiltribute = ({
  url,
  alt,
  isSelected,
  hasChildren,
  caption,
  count,
  multiselect,
}) => {
  return (
    <div
      data-testid="category-filtribute"
      className={classNames(styles.categoryFiltribute, {
        [styles.disabled]: count === 0 && !isSelected,
      })}
    >
      <div
        className={classNames(styles.categorySelectionIndicator, {
          [styles.selected]: isSelected,
        })}
      >
        <div
          className={classNames(styles.categoryIndicator, {
            [styles.selected]: isSelected,
            [styles.hasChildren]: hasChildren,
          })}
        >
          <img src={url} alt={alt} />
        </div>
      </div>
      {multiselect ? (
        <div
          className={classNames(styles.multiselect, {
            [styles.selected]: isSelected,
          })}
        >
          {isSelected && (
            <Icon icon={icons.check} size={14} color={iconsColors.WHITE} />
          )}
        </div>
      ) : null}
      <div className={styles.categoryDetails}>
        <h4>{caption}</h4>
        <p>{count}</p>
      </div>
    </div>
  )
}
