import React from "react"
import useProductImage from "./hooks/useProductImage"
import * as styles from "./productImage.module.css"

const ProductImage = ({ product, idx }) => {
  const {
    imgRef,
    cardRef,
    handleImageLoaded,
    selectedIndex,
    isInView,
    imageLoaded,
  } = useProductImage({ product, idx })

  return (
    <div ref={cardRef} className={styles.imageContainer}>
      {product &&
        product.colorOptions &&
        isInView &&
        ((product?.colorOptions[selectedIndex] &&
          product?.colorOptions[selectedIndex].productThumbnail) ||
          product.thumbnail) && (
          <img
            ref={imgRef}
            src={
              selectedIndex > -1
                ? product.colorOptions[selectedIndex].productThumbnail
                : product.thumbnail
            }
            alt={product.name}
            key={
              Array.isArray(product.colorOptions) &&
              product.colorOptions.length > 0
                ? product.colorOptions[selectedIndex] &&
                  product.colorOptions[selectedIndex].id
                : product.id
            }
            onLoad={handleImageLoaded}
            loading="lazy"
          />
        )}
      {!imageLoaded ? <div className={styles.imageScreener} /> : null}
      <div style={{ display: "none" }}>
        {product &&
          product.colorOptions &&
          isInView &&
          product.colorOptions.map(colorOption => {
            return (
              <img key={colorOption.id} src={colorOption.productThumbnail} />
            )
          })}
      </div>
    </div>
  )
}

export default ProductImage
