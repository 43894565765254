import React from "react"
import classNames from "classnames"
import { indicatorSubTypes } from "../index.js"
import { PanelLabelMain } from "./particles/panelLabels"
import useFilters from "../../features/filters/hooks/useFilters.js"
import { panelKeys } from "../index.js"
import * as styles from "./categorySelector.module.css"

export const CategoryPreview = () => {
  const { filterPanel: filterPanelObj } = useFilters({
    caller: panelKeys.SHAPE_FILTER,
    counts: false,
  })
  const isShape = filterPanelObj.selection.subType === indicatorSubTypes.SHAPE
  var imageSize = 160
  if (isShape) imageSize = 200
  if (filterPanelObj.stackSize > 1) {
    imageSize = 120
    if (isShape) imageSize = 160
  }

  const getImgUrlWithNewSize = (imgUrl, newSize) => {
    var result = imgUrl
    var fileFormat = imgUrl.split(".").slice(-1)[0]
    var imgUrlWithoutDimmensions = imgUrl
      .split(".")
      .slice(0, -1)
      .join(".")
      .split("_")[0]
    if (newSize)
      result =
        imgUrlWithoutDimmensions +
        "_" +
        newSize +
        "x" +
        newSize +
        "." +
        fileFormat
    return result
  }

  return (
    <div className={styles.categoryPreview}>
      {/* <SectionHeader />  */}
      {filterPanelObj.selection.imageUrl && (
        <div
          className={classNames(
            styles.selectionPreview,
            { [styles.categorySelection]: !isShape },
            { [styles.shapeSelection]: isShape }
          )}
        >
          {filterPanelObj.stackSize === 2 && (
            <div className={styles.doubleStackCard} />
          )}
          {filterPanelObj.stackSize === 3 && (
            <>
              {[1, 2].map((item, index) => (
                <div key={index} className={styles.trippleStackCard} />
              ))}
            </>
          )}
          {filterPanelObj.stackSize === 4 && (
            <>
              {[1, 2, 3].map((item, index) => (
                <div key={index} className={styles.fourStackCard} />
              ))}
            </>
          )}
          {filterPanelObj.stackSize >= 5 && (
            <>
              {[1, 2, 3, 4].map((item, index) => (
                <div key={index} className={styles.fiveStackCard} />
              ))}
            </>
          )}
          <div
            className={classNames(styles.selectionCard, {
              [styles.stackedCard]: filterPanelObj.stackSize > 1,
            })}
          >
            <img
              key={getImgUrlWithNewSize(
                filterPanelObj.selection.imageUrl,
                imageSize
              )}
              src={getImgUrlWithNewSize(
                filterPanelObj.selection.imageUrl,
                imageSize
              )}
              alt={filterPanelObj.selection.caption}
            />
          </div>
        </div>
      )}
      {!isShape ? (
        <PanelLabelMain centered>
          {filterPanelObj.selection.caption}
        </PanelLabelMain>
      ) : (
        <div style={{ display: "none" }} />
      )}
    </div>
  )
}
