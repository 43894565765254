import React from "react"
import { ChooseValueButton } from "../../../components"
import { ListItem } from "../../../components"

const BrandFiltribute = ({ filtribute, position }) => {
  return (
    <div key={filtribute.id}>
      <ChooseValueButton
        key={filtribute.id}
        pathname={filtribute.getLinkUrl(position)}
        enabled={filtribute.count > 0 || filtribute.selected}
      >
        <ListItem
          isSelected={filtribute.selected}
          caption={filtribute.text}
          count={filtribute.count}
        ></ListItem>
      </ChooseValueButton>
    </div>
  )
}

export default BrandFiltribute
