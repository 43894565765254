import { useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { resetLastError, resetErrors } from "../store/globalState"
import useNavigator from "./useNavigator"

const useErrors = () => {
  const timeoutRef = useRef()
  const { location } = useNavigator()
  const dispatch = useDispatch()
  const errors = useSelector(store => store?.globalState?.errors)
  const errorMessages = errors.map((error, idx) => {
    return {
      id: `${Date.now()}-${idx}`,
      message:
        error?.data?.message ||
        error?.error ||
        "Error retrieving data from server.",
    }
  })

  useEffect(() => {
    if (errors.length > 0) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null
        dispatch(resetLastError())
      }, 10000)
    }
  }, [errors])

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    dispatch(resetErrors())
  }, [location.href])

  return { errors: errorMessages }
}

export default useErrors
