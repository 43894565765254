import { apiSlice } from "../api/apiAuthenticatedSlice"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: "/login",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: [{ type: "Profile" }],
    }),
  }),
})

export const { useLoginMutation } = authApiSlice
