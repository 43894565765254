import { useRef, useEffect, useState } from "react"
import useKeywordSearch from "../../keywordSearch/hooks/useKeywordSearch"

const useDropdown = () => {
  const dropdownRef = useRef()
  const searchTextRef = useRef()
  const { searchText, setSearchText } = useKeywordSearch()
  const [openDropdown, setOpenDropdown] = useState()

  const closeDropdown = () => {
    setSearchText("")
    setOpenDropdown(null)
  }

  useEffect(() => {
    if (searchText && openDropdown !== "textSearch") {
      setOpenDropdown("textSearch")
    } else if (!searchText && openDropdown) {
      closeDropdown()
    }
  }, [searchText])

  const handleClick = event => {
    if (dropdownRef.current) {
      if (!dropdownRef.current.contains(event.target)) {
        if (
          searchTextRef.current &&
          !searchTextRef.current.contains(event.target)
        )
          closeDropdown()
      }
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
    // eslint-disable-next-line
  }, [])

  return { searchTextRef, dropdownRef, openDropdown }
}

export default useDropdown
