import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { discardToken } from "../../features/auth/authSlice"
import { setError } from "../../store/globalState"
/*
const BASE_URL = window.urbanoTestApi
  ? "https://sandbox-api.vestigiotools.com/filipa/tools" //window.urbanoTestApi
  : process.env.REACT_APP_API_URL
*/
const BASE_URL = "https://sandbox-api.vestigiotools.com/" // filipa/tools"

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  /*
  Original code had the setting: "include", but it gives CORS error.

  Explanation:

  The reason you are getting a CORS error with credentials: "include" and not with credentials: "same-origin" is due to the CORS policy of the API you are requesting from.

  When making a cross-origin request with credentials: "include", the browser sends a preflight request to the API to check whether the request is allowed. This preflight request includes an Origin header with the domain of the requesting site, as well as other headers and options related to the request.

  The API should respond to the preflight request with appropriate headers, including the Access-Control-Allow-Credentials header with a value of true. This header indicates that the API allows requests with credentials from the requesting domain.

  However, in your case, it appears that the API is not sending the Access-Control-Allow-Credentials header with a value of true in response to the preflight request. This is causing the browser to block the request and return the CORS error you are seeing.

  On the other hand, when you set credentials: "same-origin", the request does not require a preflight request, and therefore the Access-Control-Allow-Credentials header is not required. This is why you are not getting a CORS error when using credentials: "same-origin".

  When you set the authorization header manually in the prepareHeaders function, it will be included in the request regardless of the credentials option.

  The credentials option controls whether the browser should include cookies or other credentials that are associated with the origin of the request, but it does not affect any headers that are manually added to the request.

  So in your case, if the authentication token is being included in the authorization header manually, it will be sent with the request regardless of the credentials option.
  */
  // Credentials setting is needed for JSON Web Token (JWT) authentication is used since JWT uses cookies that are not accessible for browser
  // credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

const parseReadableStream = async stream => {
  if (!stream) return null
  const reader = stream.getReader()
  let result = ""
  try {
    const { value } = await reader.read()
    const decoder = new TextDecoder("utf-8")
    const decoded = decoder.decode(value)
    result = JSON.parse(decoded)
  } catch (error) {
    console.error("Error parsing ReadableStream:", error)
  } finally {
    reader.releaseLock()
    return result
  }
}

const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error) {
    const status = result?.error?.status || result?.error?.originalStatus
    if (status === 401) {
      if (
        result?.error?.data?.message === "Token store appears to be missing"
      ) {
        await timeout(500)
        return baseQueryWithReauth(args, api, extraOptions)
      } else {
        api.dispatch(discardToken())
      }
    } else if (status === 504) {
      await timeout(500)
      return baseQueryWithReauth(args, api, extraOptions)
    } else {
      if (result?.meta?.request) {
        const stream = result.meta.request.body
        const body = await parseReadableStream(stream)
        result.error.request = {
          url: result.meta.request.url,
          body,
        }
      }
      api.dispatch(setError(result.error))
    }
  }
  return result
}

export const baseUrl = BASE_URL

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "apiAuthenticated",
  endpoints: builder => ({}),
})
